import React, { useContext, useEffect, useRef, useState } from "react";
import MessagePopup from "../MessagePopup/MessagePopup";
import successTick from "../../assests/successTick.svg";
import fileSize from "../../assests/fileSize.svg";
import { useUserAuth } from "../../context/UserAuthContext";
import Context from "../../context/Context";
import _, { isEmpty } from "lodash";
import axios from "axios";
import styles from "./Styles/DefaultTemplate.module.css";
import { leadsStorage } from "../../store/firebase-config";
import { ref, uploadBytesResumable } from "firebase/storage";
import { useNavigate } from "react-router";
import minus from "../../assests/Cartminus.svg";
import plus from "../../assests/Cartadd.svg";
import copy from "../../assests/copyIcon.svg";
import copiedPng from "../../assests/copied.svg";
import upiImage from "../../assests/UPI.png";
import downArrow from "../../assests/downArrowBlack.svg";
import uploadIcon from "../../assests/uploadIcon.svg";
import upArrow from "../../assests/upArrowBlack.svg";
import UploadPopup from "../UploadPopup/UploadPopup";

import { createSearchParams, Link, useSearchParams } from "react-router-dom";
import { payIcon } from "../../utils/constants";
import Searching from "../CountDown/Searching";
import ProductCard from "./ProductCard";
import CompletionBanner from "./CompletionBanner";

export default function DefaultTemplate({
  setShowTerms,
  promotionData,
  setServiceForm,
  mail,
  service,
}) {
  const [leadsSubmitted, setLeadsSubmitted] = useState(false);
  const {
    currentUser,
    setPreviousLocation,
    setShowLogin,
    reqForLeadsSubmit,
    setReqLeadsSubmit,
    userDetailsFromApi,
  } = useUserAuth();
  const {
    userIdToken,
    campaignInfId,
    gallaryClick,
    setGallaryClick,
    tinyUrlFromMetaData,
  } = useContext(Context);
  const token = userIdToken || currentUser?.accessToken;
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const tinyUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");
  let promotion = tinyUrl.startsWith("pro");
  let promotionOrgId = promotionData?.organizationId;
  let promotionEntryId = promotionData?._id;
  const navigate = useNavigate();
  const [countryCodes, setCountryCodes] = useState([]);
  const [searchParams] = useSearchParams();
  const [isType, setIsType] = useState("");
  const [copied, setCopied] = useState("");
  const [upiName, setUpiName] = useState("");
  const [screenShotOpen, setScreenShotOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [source, setSource] = useState("");
  const [file, setFile] = useState();
  const [filterdCountryCodes, setFilteredCountryCode] = useState([]);
  let paymentNamesAndSvg = [];
  const [progress, setProgress] = useState([]);
  const initialValues = {
    name: currentUser?.displayName || "",
    userEmail: currentUser?.email || "",
    email: mail,
    tc: true,
    promotion: promotion,
    shareUrl: tinyUrl,
    infId: campaignInfId,
    paymentDoc: "",
    paymentModes:
      (promotionData?.paymentModes && promotionData?.paymentModes[0]?.type) ||
      "",
    paymentMethod:
      (promotionData?.paymentModes &&
        promotionData?.paymentModes[0]?.paymentMode) ||
      "",
    offeringDate:
      promotionData?.dateMode == "CALENDAR" ? "" : promotionData?.dateMode,
  };
  const nameRef = useRef();
  const birthStarRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const countRef = useRef();
  const dropdownRef = useRef();
  const imageUrlRef = useRef();
  const summaryRef = useRef();
  const termsRef = useRef();
  const offeringDateRef = useRef();
  const [errorText, setErrorText] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [numberObj, setNumberObj] = useState({
    countryCode: "",
    number: null,
  });
  const [formValues, setFormValues] = useState(initialValues);
  console.log("for", formValues);

  const [sortedProducts, setSortedProducts] = useState([]);
  const [cartMap, setCartMap] = useState(new Map());
  useEffect(() => {
    if (reqForLeadsSubmit) {
      handleSubmit();
      setReqLeadsSubmit(false);
    }
  }, [currentUser]);
  // useEffect(() => {
  //   getCountryCodes();
  // }, []);
  useEffect(() => {
    handleCountryCodeChange({
      name: "India",
      code: "+91",
      shortCode: "IN",
    });
  }, []);
  useEffect(() => {
    sortProducts();
  }, []);
  const sortProducts = () => {
    let products = [...promotionData?.products];
    const targetProductId = gallaryClick?.productId;
    if (targetProductId) {
      const targetProductIndex = products?.findIndex(
        (product) => product?.productId === targetProductId
      );
      if (targetProductIndex !== -1) {
        const [targetProduct] = products.splice(targetProductIndex, 1);
        products?.unshift(targetProduct);
        setSortedProducts(products);
      }
    } else {
      setSortedProducts([...promotionData?.products]);
    }
  };

  function handleRedirectToLink(link, paymentRedirectUrl) {
    const form = document.createElement("form");
    form.method = "get";
    form.action =
      paymentRedirectUrl || "https://www.shootmates.com/thirdpartyPayment";
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "gatewayresp";
    input.id = "gatewayresp";
    input.value = link;
    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
  }

  const getCountryCodes = async () => {
    try {
      const data = await axios.get(
        `https://storage.googleapis.com/goviralcampaign/CountryCodes.json`
      );
      setCountryCodes(data.data);
      setFilteredCountryCode(data.data);
    } catch (error) {
      console.log(error.message);
      //navigate("/error")
    }
  };
  const handleCountryCodeChange = (country) => {
    let { code } = country;
    setSelectedCountry(country);
    setNumberObj((prevState) => ({
      ...prevState,
      countryCode: code,
    }));
    setIsDropdownOpen(false);
  };
  const toggleOpen = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const getCountryFlag = (code) => {
    let lowerCaseCode = code?.toLowerCase();
    return (
      <img
        src={`https://storage.googleapis.com/goviralcampaign/countryFlags/${lowerCaseCode}.png`}
        className="h-full w-full "
      />
    );
  };
  const imageSrc = isDropdownOpen ? upArrow : downArrow;
  const handleMediaSelect = async (event) => {
    const image = new FormData();
    let id = event.target.id;
    const imagedata = event.target.files[0];
    image.append("image", imagedata);
    let url = URL.createObjectURL(imagedata);
    setFile(imagedata);
    setSource(url);
  };
  const handleChoose = async () => {
    imageUrlRef.current.click();
  };
  const validateEmail = (emailAdress) => {
    // eslint-disable-next-line no-useless-escape
    let regexEmail =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailAdress?.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  };
  const handleCountryChange = (event) => {
    const inputValue = event.target.value;

    // Filter country codes based on user input
    const filteredCountryCodes = countryCodes?.filter((country) =>
      country.code.includes(inputValue)
    );

    setFilteredCountryCode(filteredCountryCodes);
    setNumberObj((prevState) => ({
      ...prevState,
      countryCode: event.target.value,
    }));
    // Update the dropdown visibility
    setIsDropdownOpen(filteredCountryCodes.length > 0);

    // When the input is cleared, close the dropdown
    if (!inputValue) {
      setIsDropdownOpen(false);
    }
  };
  const validatePhone = (number) => {
    if (number?.length > 4) {
      return true;
    } else {
      return false;
    }
  };
  promotionData?.paymentModes?.map((item) => {
    const payMode = item?.type;
    let svgIcon;
    let Name;

    switch (payMode) {
      case "COD":
        Name = "Cash On delivery";
        svgIcon = payIcon?.codIcon;
        break;
      case "UPI":
        Name = "Upi Payment";
        svgIcon = "";
        break;
      case "PHONEPE":
        Name = "Pay Online";
        svgIcon = payIcon?.cardIcon;
        break;
      case "RAZORPAY":
        Name = "Pay Online";
        svgIcon = payIcon?.cardIcon;
        break;
      case "BANK_TRANSFER":
        Name = "Bank Transfer";
        svgIcon = payIcon?.walletIcon;
        break;
    }
    paymentNamesAndSvg.push({
      Name: Name,
      svgIcon: svgIcon,
    });
  });
  const handleSubmit = async (e) => {
    // e.preventDefault();
    let validateResponse = validate(formValues);
    setFormErrors(validateResponse);
    if (_.isEmpty(validateResponse)) {
      if (
        formValues?.paymentModes === "BANK_TRANSFER" ||
        formValues?.paymentModes === "UPI"
      ) {
        const response = await uploadGoviralMedias();
        submitFormApi(response);
      } else {
        submitFormApi();
      }
    }
  };

  const handleLogin = () => {
    setPreviousLocation(window.location.pathname);
    localStorage.setItem("previousLocationLocal", window.location.pathname);
    setShowLogin(true);
    setReqLeadsSubmit(true);
  };

  const buttonColor = () => {
    if (promotion) {
      return {
        background: promotionData?.btnColor,
        color: promotionData?.btnTextColor,
      };
    } else {
      return { background: service?.btnColor, color: service?.btnTextColor };
    }
  };

  const validate = (values) => {
    const errors = {};
    let ref = "";
    let errorMessage = "";
    // if (!values.name) {
    //   errors.name = "This field is required";
    //   errorMessage = "Please Enter Your Name";
    //   ref = nameRef;
    // }
    // if (!values.birthStar) {
    //   errors.birthStar = "This field is required";
    //   errorMessage = "Please Enter Your Birth Star";
    //   ref = birthStarRef;
    // }
    // if (!validateEmail(values.userEmail)) {
    //   errors.userEmail = "Please enter a valid Email";
    //   emailRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "start",
    //   });
    // }

    if (!validatePhone(numberObj?.number)) {
      errors.phone = "Please enter a valid phone number";
      errorMessage = "Please Enter Your Whatsapp Number";
      ref = phoneRef;
    }
    if (!values.totalCount) {
      errors.totalCount = "Please add a person to respective items to continue";
      errorMessage = "Please add a person to continue";
      ref = countRef;
    }
    if (promotionData?.dateMode && !values?.offeringDate) {
      errors.offeringDate = "Please enter offering date";
      errorMessage = "Please Select a Date";
      ref = offeringDateRef;
    }
    if (
      !source &&
      (values?.paymentModes === "BANK_TRANSFER" ||
        values?.paymentModes === "UPI")
    ) {
      errorMessage = "Please upload payment screenshot";
      errors.imageUrl = "Please upload payment screenshot";
    }
    if (values.tc === false) {
      errorMessage = "Please accept the terms and conditions";
      errors.tc = "Please accept the terms and conditions";
      ref = termsRef;
    }
    if (errorMessage) {
      setErrorText(errorMessage);
    }
    if (ref) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
    return errors;
  };

  const uploadGoviralMedias = async () => {
    setLoading(true);
    try {
      const fileData = file;
      let randomId = Math.floor(Date.now() * Math.random());
      let fileName = `${randomId}_${file?.name?.replace(/ /g, "")}`;
      const folderPath = `/${promotionEntryId}/${userDetailsFromApi?.userId}_${fileName}`;
      const storageRef = ref(leadsStorage, folderPath);
      const uploadTask = uploadBytesResumable(storageRef, fileData);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const uploadprogress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress((prevProgress) => {
            const newProgress = [...prevProgress];
            newProgress[0] = uploadprogress;

            return newProgress;
          });

          switch (snapshot.state) {
            case "paused":
              break;
            case "running":
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          throw error;
        }
      );
      // Wait for the upload task to complete before proceeding to the next file
      await uploadTask;

      let fileUrl = "https://storage.googleapis.com/leads_media" + folderPath;
      return fileUrl;
    } catch (error) {
      console.error(error);
      setLoading(false);
      setProgress([]);
      return { error: true };
    }
  };
  const submitFormApi = async (paymentDoc) => {
    setLoading(true);
    try {
      let dataToSubmit = {};
      let product_purchased = Array.from(cartMap?.values())?.map(
        (product) => product
      );
      let phone = (numberObj?.countryCode + numberObj?.number)?.replace(
        /\s+/g,
        ""
      );
      dataToSubmit = {
        ...formValues,
        product_purchased,
        phone,
        paymentDoc: paymentDoc,
      };

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_PURCHASE}/${promotionOrgId}/${promotionEntryId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSubmit,
      };
      const response = await axios(config);
      const orderId = response?.data?.orderId;
      if (response?.data?.status == "success") {
        if (formValues?.paymentMethod === "PAYMENT_GATEWAY") {
          //phonepay api
          config = {
            method: "post",
            url: `${process.env.REACT_APP_INITIATE_PAYMENT_PHONEPE_HOMAM}/${promotionOrgId}/${promotionEntryId}/${orderId}?paymentMode=${formValues?.paymentModes}`,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            data: {
              tinyUrl: formValues?.shareUrl,
            },
          };
          const submitPay = await axios(config);
          if (submitPay?.data?.status == "success") {
            let paymentRedirectUrl = submitPay?.data?.paymentRedirectUrl;
            let url = submitPay?.data?.paymentUrl;
            handleRedirectToLink(url, paymentRedirectUrl);
          }
        }
        // setLeadsSubmitted(true);
        // navigate(`/${tinyUrl}`);
        // setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleCollectUserInputs = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleAdd = (selectedProduct) => {
    const key = selectedProduct?.productId;
    const value = {
      ...selectedProduct,
      count: cartMap.get(key)?.count ? cartMap.get(key)?.count + 1 : 1,
    };
    const updatedMap = new Map(cartMap);
    updatedMap.set(key, value);
    setCartMap(updatedMap);

    setFormValues((prevState) => {
      let totalCount = 0;
      let totalAmt = 0;

      updatedMap?.forEach((product) => {
        totalCount += product?.count;
        totalAmt += product?.price * product?.count;
      });
      return {
        ...prevState,
        totalAmt,
        totalCount,
      };
    });
  };

  const handleRemove = (selectedProduct) => {
    const key = selectedProduct?.productId;
    const value = {
      ...selectedProduct,
      count: cartMap.get(key)?.count ? cartMap.get(key)?.count - 1 : 0,
    };
    const updatedMap = new Map(cartMap);
    updatedMap.set(key, value);
    setCartMap(updatedMap);

    setFormValues((prevState) => {
      let totalCount = 0;
      let totalAmt = 0;

      updatedMap?.forEach((product) => {
        totalCount += product?.count;
        totalAmt += product?.price * product?.count;
      });
      return {
        ...prevState,
        totalAmt,
        totalCount,
      };
    });
  };

  const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(text);
      const timeout = setTimeout(() => {
        setCopied(false);
      }, [2000]);
    } catch (error) {
      console.error("Unable to copy text:", error);
    }
  };
  const handleClick = (type, paymentMethod, upiInfo) => {
    setFormValues((prevState) => ({
      ...prevState,
      paymentModes: type,
      paymentMethod,
    }));
    if (isType === type) {
      setIsType("");
    } else {
      setIsType(type);
    }

    if (type === "PHONEPE" || type === "COD" || type === "RAZORPAY") {
      //gateway case

      return;
    } else {
      if (upiInfo === upiName) {
        setUpiName("");
        setScreenShotOpen(false);
      } else {
        setUpiName(upiInfo);
        setScreenShotOpen(true);
      }
    }
  };

  const uploadMessage = "Submitting data - Please wait...";
  const message = "Order successfully submitted. We'll get back to you soon!";

  return (
    <>
      {!isEmpty(progress) && (
        <UploadPopup progress={progress} message={uploadMessage} />
      )}
      {loading && <Searching message={uploadMessage} />}
      {leadsSubmitted && (
        <MessagePopup
          logo={successTick}
          setPopup={setLeadsSubmitted}
          setMainPopup={setServiceForm}
          message={message}
        />
      )}
      {errorText && (
        <MessagePopup
          logo={fileSize}
          setPopup={setErrorText}
          setMainPopup={setErrorText}
          heading={errorText}
        />
      )}
      <CompletionBanner
        onClick={handleSubmit}
        loading={loading}
        buttonColor={buttonColor}
        price={formValues?.totalAmt}
        summaryRef={summaryRef}
      />
      <div className="h-[100%] w-[100%] text-black px-[16px] md:px-[12%] pt-[3%] pb-[80px]">
        {currentUser && (
          <Link
            to={{
              pathname: "purchase",
              search: `${searchParams ? createSearchParams(searchParams) : ""}`,
            }}
          >
            <p className="text-right mb-[10px] text-small cursor-pointer">
              Already Purchased?
              <span className="ml-[5px]  underline text-[#3075FC]">
                Show My Purchase
              </span>
            </p>
          </Link>
        )}

        <p className="leadcardtext">
          Please fill out the following information.
        </p>

        <>
          <div className="seperator-black mt-[30px] mb-[20px]" />

          {promotionData?.dateMode && (
            <>
              <div className="mb-[20px]">
                <p className="text-regular-medium">
                  വഴിപാട് ദിവസം
                  <span className="text-[#FA6B6B]">*</span>
                </p>
                <input
                  className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none disabled:opacity-50 appearance-none${
                    formErrors.offeringDate ? styles.errorOutline : ""
                  }`}
                  type="date"
                  id="offeringDate"
                  value={formValues?.offeringDate}
                  onChange={handleCollectUserInputs}
                  disabled={promotionData?.dateMode !== "CALENDAR"}
                  ref={offeringDateRef}
                />
                {formErrors.offeringDate && (
                  <span className={styles.error}>
                    <span className="text-red-600">*</span>
                    {formErrors.offeringDate}
                  </span>
                )}
              </div>
            </>
          )}

          <p className="text-regular-medium mb-[10px]">Offerings</p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-[16px]">
            {sortedProducts?.map((obj) => (
              <ProductCard
                obj={obj}
                cartMap={cartMap}
                setCartMap={setCartMap}
                handleRemove={handleRemove}
                handleAdd={handleAdd}
                setFormValues={setFormValues}
              />
            ))}
          </div>
          <div ref={countRef} />
          {formErrors.totalCount && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {formErrors.totalCount}
            </span>
          )}
          <form className={styles.form}>
            {/* NAME  */}
            {/* <div className="mt-[20px]">
            <p>
              Name <span className="text-[#FA6B6B]">*</span>
            </p>
            <input
              className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                formErrors.name ? styles.errorOutline : ""
              }`}
              type="text"
              id="name"
              value={formValues?.name}
              onChange={handleCollectUserInputs}
              ref={nameRef}
            />
            {formErrors.name && (
              <span className={styles.error}>
                <span className="text-red-600">*</span>
                {formErrors.name}
              </span>
            )}
          </div> */}
            {/* BIRTH START  */}
            {/* <div className="mt-[20px]">
            <p>
              Birth Star <span className="text-[#FA6B6B]">*</span>
            </p>
            <input
              className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                formErrors.birthStar ? styles.errorOutline : ""
              }`}
              type="text"
              id="birthStar"
              value={formValues?.birthStar}
              onChange={handleCollectUserInputs}
              ref={birthStarRef}
            />
            {formErrors.birthStar && (
              <span className={styles.error}>
                <span className="text-red-600">*</span>
                {formErrors.birthStar}
              </span>
            )}
          </div> */}
            {/* EMAIL  */}
            {/* <div className="mt-[25px]">
            <p>
              Email <span className="text-[#FA6B6B]">*</span>
            </p>
            <input
              className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px] ${
                formErrors.userEmail ? styles.errorOutline : ""
              } ${styles.placeholder}`}
              type="email"
              id="userEmail"
              value={formValues?.userEmail}
              placeholder="Enter email to receive additional information"
              onChange={handleCollectUserInputs}
              ref={emailRef}
            />
            {formErrors.userEmail && (
              <span className={styles.error}>
                <span className="text-red-600">*</span>
                {formErrors.userEmail}
              </span>
            )}
          </div> */}
            {/* NUMBER  */}
            <div className="mt-[25px]">
              <p>
                Whatsapp Number <span className="text-[#FA6B6B]">*</span>
              </p>
              <div
                className={`flex items-center relative w-full mt-[10px] ${
                  formErrors.phone ? styles.errorOutline : ""
                }`}
              >
                <div
                  ref={dropdownRef}
                  className={styles.country}
                  onClick={toggleOpen}
                >
                  <img src={imageSrc} />
                  {!_.isEmpty(selectedCountry) && (
                    <div className="aspect-[4/3] h-[20px]">
                      {getCountryFlag(selectedCountry?.shortCode)}
                    </div>
                  )}
                  {isDropdownOpen && (
                    <div className={styles.dropdownList}>
                      {filterdCountryCodes?.map((country) => (
                        <div
                          key={country?.shortCode}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCountryCodeChange(country);
                          }}
                          className="flex items-center gap-[10px] p-[10px]"
                        >
                          <div className="w-[30px] h-[20px]">
                            {getCountryFlag(country?.shortCode)}
                          </div>
                          {country?.code} - {country?.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className={styles.phoneInput}>
                  <input
                    type="text"
                    id="countryCode"
                    name="countryCode"
                    maxlength="4"
                    className="text-regular-callout"
                    onChange={handleCountryChange}
                    onClick={toggleOpen}
                    autoComplete="off"
                    value={numberObj?.countryCode}
                    readOnly
                  />
                  <input
                    className={`w-[100%] px-[16px] py-[15px] text-[14px]
                ${styles.placeholder} 
                `}
                    type="text"
                    placeholder="Share your number"
                    id="phone"
                    value={formValues?.phone}
                    onChange={(e) =>
                      setNumberObj((prevState) => ({
                        ...prevState,
                        number: e.target.value,
                      }))
                    }
                    ref={phoneRef}
                  />
                </div>
              </div>
              {formErrors.phone && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.phone}
                </span>
              )}
            </div>
            <div className="seperator-black mt-[30px] mb-[20px]" />
          </form>
          {formValues?.totalCount > 0 && (
            <div
              className="mt-[25px] py-[25px] px-[5px] sm:px-[25px] bg-[#eee]"
              ref={summaryRef}
            >
              <p className="text-regular-medium">Summary</p>
              <table className={styles.table}>
                {Array.from(cartMap?.values())?.map((product) => (
                  <>
                    {product?.count > 0 && (
                      <tr>
                        <td className="text-small">{product?.productTitle}</td>
                        <td className="text-small flex gap-[5px] items-center cursor-pointer w-fit">
                          {/* <img
                            onClick={() => handleRemove(product)}
                            src={minus}
                            className="shrink-0 w-[18px] h-[18px]"
                          /> */}
                          <p className="w-fit border-[1px] shrink-0 border-[#E8E8E8] py-[5px] px-[12px] text-small">
                            {cartMap.get(product?.productId)?.count
                              ? cartMap?.get(product?.productId)?.count
                              : 0}
                          </p>
                          {/* <img
                            onClick={() => handleAdd(product)}
                            src={plus}
                            className="shrink-0 w-[18px] h-[18px]"
                          /> */}
                        </td>
                        <td className="text-small">
                          ₹{product.price * product.count}
                        </td>
                      </tr>
                    )}
                  </>
                ))}
                <tr>
                  <td style={{ padding: 0 }}>
                    <div className="seperator-black w-full" />
                  </td>
                  <td style={{ padding: 0 }}>
                    <div className="seperator-black w-full" />
                  </td>
                </tr>
                <tr>
                  <td className="text-small-bold">Total:</td>
                  <td className="text-small-bold text-center">
                    {formValues?.totalCount}
                  </td>
                  <td className="text-small-bold">₹{formValues?.totalAmt}</td>
                </tr>
              </table>

              {/* {promotionData?.paymentModes?.map((item, i) => (
                <>
                  {paymentNamesAndSvg?.[i]?.Name && (
                    <div
                      onClick={() => handleClick(item?.type,item?.paymentMode, item?.upiName)}
                      className={`${
                        i === 0 && "mt-[15px]"
                      } justify-between items-center px-[10px] gap-[12px] flex border-y-[1px] py-[16px] border-y-[#D0D0D0]`}
                    >
                      <div className="flex items-center gap-[20px]">
                        <input
                          type="checkbox"
                          value={item?.type}
                          checked={formValues?.paymentModes === item?.type}
                        />{" "}
                        {paymentNamesAndSvg?.[i]?.Name === "Upi Payment" ? (
                          <img
                            className="w-[24px] h-[8px] object-cover"
                            src={upiImage}
                            alt=""
                          />
                        ) : (
                          paymentNamesAndSvg?.[i]?.svgIcon
                        )}
                        <p className=" flex gap-2 text-[14px] font-[500]">
                          {" "}
                          {paymentNamesAndSvg?.[i]?.Name}
                        </p>
                      </div>

                      <div>
                        {screenShotOpen && item?.type === isType ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            viewBox="0 0 18 11"
                            fill="none"
                          >
                            <path
                              d="M17.2396 1.90623L8.90627 10.2396L0.572937 1.90623L2.0521 0.427065L8.90627 7.28123L15.7604 0.427065L17.2396 1.90623Z"
                              fill="black"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="13"
                            viewBox="0 0 10 17"
                            fill="none"
                          >
                            <path
                              d="M1.47917 -7.39046e-06L9.8125 8.33332L1.47917 16.6667L1.95544e-06 15.1875L6.85417 8.33332L1.95458e-06 1.47916L1.47917 -7.39046e-06Z"
                              fill="black"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  )}

                  {item?.type === isType && item?.type === "BANK_TRANSFER" && (
                    <div className="my-[20px]">
                      <div className="mx-auto border-[#787878] w-[90%] border-[1px] rounded-[10px] text-[12px] p-[10px]  ">
                        <table className="w-full table-auto">
                          <tbody>
                            {item?.bankName && (
                              <tr className="w-full">
                                <td className="text-[10px]">Bank Name:</td>
                                <td className="text-[15px] text-left">
                                  {item?.bankName}
                                </td>
                                <td
                                  className={`${
                                    item?.bankName === copied
                                      ? "bg-[#fff]"
                                      : "bg-[#ddd]"
                                  } py-[4px] px-[6px] rounded-[5px] w-fit`}
                                  onClick={() => copyText(item?.bankName)}
                                >
                                  <img
                                    className="w-[18px] h-[18px]"
                                    src={
                                      item?.bankName === copied
                                        ? copiedPng
                                        : copy
                                    }
                                    alt=""
                                  />
                                </td>
                              </tr>
                            )}
                            {item?.holderName && (
                              <tr className="w-full">
                                <td className="text-[10px]">
                                  Account Holder Name:
                                </td>
                                <td className="text-[15px] text-left">
                                  {item?.holderName}
                                </td>
                                <td
                                  className={`${
                                    item?.holderName === copied
                                      ? "bg-[#fff]"
                                      : "bg-[#ddd]"
                                  } py-[4px] px-[6px] rounded-[5px] w-fit`}
                                  onClick={() => copyText(item?.holderName)}
                                >
                                  <img
                                    className="w-[18px] h-[18px]"
                                    src={
                                      item?.holderName === copied
                                        ? copiedPng
                                        : copy
                                    }
                                    alt=""
                                  />
                                </td>
                              </tr>
                            )}
                            {item?.acNumber && (
                              <tr className="w-full">
                                <td className="text-[10px]">ACNO:</td>
                                <td className="text-[15px] text-left">
                                  {item?.acNumber}
                                </td>
                                <td
                                  className={`${
                                    item?.acNumber === copied
                                      ? "bg-[#fff]"
                                      : "bg-[#ddd]"
                                  } py-[4px] px-[6px] rounded-[5px] w-fit`}
                                  onClick={() => copyText(item?.acNumber)}
                                >
                                  <img
                                    className="w-[18px] h-[18px]"
                                    src={
                                      item?.acNumber === copied
                                        ? copiedPng
                                        : copy
                                    }
                                    alt=""
                                  />
                                </td>
                              </tr>
                            )}
                            {item?.ifscCode && (
                              <tr className="w-full">
                                <td className="text-[10px]">IFSC:</td>
                                <td className="text-[15px] text-left">
                                  {item?.ifscCode}
                                </td>
                                <td
                                  className={`${
                                    item?.ifscCode === copied
                                      ? "bg-[#fff]"
                                      : "bg-[#ddd]"
                                  } py-[4px] px-[6px] rounded-[5px] w-fit`}
                                  onClick={() => copyText(item?.ifscCode)}
                                >
                                  <img
                                    className="w-[18px] h-[18px]"
                                    src={
                                      item?.ifscCode === copied
                                        ? copiedPng
                                        : copy
                                    }
                                    alt=""
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex flex-col gap-[10px] my-[20px]">
                        <label
                          htmlFor="imageUrl"
                          className="text-regular-medium"
                        >
                          <input
                            ref={imageUrlRef}
                            className="hidden"
                            type="file"
                            onChange={handleMediaSelect}
                            accept="image/*"
                            id="imageUrl"
                          />
                          Upload Payment Screenshot
                          <span className="text-red-600">*</span>
                        </label>

                        <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                          <div
                            className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed border-[#787878]
                            ${formErrors.imageUrl ? "border-[#fa6b6b]" : ""}
                              `}
                            onClick={handleChoose}
                          >
                            {source ? (
                              <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                                <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                  <img
                                    src={source}
                                    className="outline-none w-auto h-[100%] object-cover"
                                  />
                                </div>
                                <p>Change Screenshot</p>
                              </div>
                            ) : (
                              <div>
                                <img
                                  src={uploadIcon}
                                  className="h-[80px] mx-auto"
                                />
                                <p>Select Payment Screenshot</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {item?.upi_id && item?.upiName === upiName && (
                    <>
                      <div className="my-6 w-[90%] border-[1px]  mx-auto py-4 rounded-[20px] border-[#787878]  flex text-start justify-between px-[10px] items-center ">
                        <p className="text-[15px]">
                          <span className="text-[10px]">{item?.upiName}:</span>{" "}
                          {item?.upi_id}{" "}
                        </p>{" "}
                        <div
                          className={`${
                            item?.upi_id === copied ? "bg-[#fff]" : "bg-[#ddd]"
                          } py-[4px] px-[6px] rounded-[5px]`}
                          onClick={() => copyText(item?.upi_id)}
                        >
                          <img
                            className="w-[18px] h-[18px]"
                            src={item?.upi_id === copied ? copiedPng : copy}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="w-[100%] mb-6"></div>
                      <div className="flex flex-col gap-[10px] my-[20px]">
                        <label
                          htmlFor="imageUrl"
                          className="text-regular-medium"
                        >
                          <input
                            ref={imageUrlRef}
                            className="hidden"
                            type="file"
                            onChange={handleMediaSelect}
                            accept="image/*"
                            id="imageUrl"
                          />
                          Upload Payment Screenshot
                          <span className="text-red-600">*</span>
                        </label>

                        <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                          <div
                            className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed border-[#787878]
                            ${formErrors.imageUrl ? "border-[#fa6b6b]" : ""}
                              `}
                            onClick={handleChoose}
                          >
                            {source ? (
                              <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                                <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                  <img
                                    src={source}
                                    className="outline-none w-auto h-[100%] object-cover"
                                  />
                                </div>
                                <p>Change Screenshot</p>
                              </div>
                            ) : (
                              <div>
                                <img
                                  src={uploadIcon}
                                  className="h-[80px] mx-auto"
                                />
                                <p>Select Payment Screenshot</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ))} */}
              {formErrors.imageUrl && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.imageUrl}
                </span>
              )}
            </div>
          )}
        </>
        <div>
          <div className="flex justify-start gap-[10px] mt-[25px]">
            <input
              ref={termsRef}
              type="checkbox"
              id="tc"
              name="tc"
              value=""
              checked={formValues?.tc}
              onClick={() =>
                setFormValues((prev) => ({ ...prev, tc: !prev?.tc }))
              }
              className="w-[30px] h-[30px] rounded-[5px] "
            />
            <label
              htmlFor="tc"
              className="text-[#171717] font-Inter font-[400] text-[14px] leading-[24px]"
            >
              Yes I agree to the{" "}
              <span
                className="text-blue-500 hover:cursor-pointer"
                onClick={() => setShowTerms(true)}
              >
                Terms & Conditions
              </span>{" "}
              . Hereby agree to be contacted for enquiry purpose of the same.
            </label>
          </div>
          {formErrors.tc && (
            <span className={styles.error}>
              <span className="text-red-600">*</span>
              {formErrors.tc}
            </span>
          )}
        </div>
        <div className="w-[100%] flex justify-center mt-[30px]">
          <button
            className="drop-shadow-lg text-regular-medium h-[52px] w-auto px-[20px]  rounded-full flex justify-center items-center"
            onClick={handleSubmit}
            style={buttonColor()}
            disabled={loading}
          >
            {loading ? "Submitting" : "Pay and Submit"}
          </button>
        </div>
        <pre className="py-[5px] text-smallest text-center mt-[5px] text-[#5d5d5d]">
          {"Payment powered by \nMEMSHOTS PVT LTD."}
        </pre>
      </div>
    </>
  );
}
