import React, { useEffect, useState } from "react";
import styles from "./AddPersonPopup.module.css";
import PopupWrapper from "../../Layout/PopupWrapper";
import { isEmpty } from "lodash";

const AddPersonPopup = ({ setPopup, handleAddNewPerson, familyName }) => {
  const [personData, setPersonData] = useState({
    customerName: "",
    birthStar: "",
    familyName: "",
  });
  const [formErrors, setFormErrors] = useState({});
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  const handleCollectUserInputs = (e) => {
    setPersonData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const handleAdd = () => {
    const errors = {};
    if (!personData.customerName) {
      errors.customerName = "This field is required";
    }
    if (!personData.birthStar && !familyName) {
      errors.birthStar = "Please Select a Birth Star";
    }
    setFormErrors(errors);
    if (isEmpty(errors)) {
      handleAddNewPerson(personData);
    }
  };
  const birthStars = [
    "അശ്വതി",
    "ഭരണി",
    "കാർത്തിക",
    "രോഹിണി",
    "മകയിരം",
    "തിരുവാതിര",
    "പുണർതം",
    "പൂയം",
    "ആയില്യം",
    "മകം",
    "പൂരം",
    "ഉത്രം",
    "അത്തം",
    "ചിത്തിര",
    "ചോതി",
    "വിശാഖം",
    "അനിഴം",
    "തൃക്കേട്ട",
    "മൂലം",
    "പൂരാടം",
    "ഉത്രാടം",
    "തിരുവോണം",
    "അവിട്ടം",
    "ചതയം",
    "പൂരുരുട്ടാതി",
    "ഉത്രട്ടാതി",
    "രേവതി",
  ];
  return (
    <PopupWrapper>
      <div className={styles.mainDiv}>
        <div className={styles.container}>
          <form>
            <div className="mt-[20px]">
              <label>Name</label>
              <input
                type="text"
                id="customerName"
                value={personData?.customerName}
                className={`${
                  formErrors.customerName ? styles.errorOutline : ""
                }`}
                onChange={handleCollectUserInputs}
              />
              {formErrors.customerName && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.customerName}
                </span>
              )}
            </div>
            {familyName && (
              <div className="mt-[20px]">
                <label>Family Name</label>
                <input
                  type="text"
                  id="familyName"
                  value={personData?.familyName}
                  className={`${
                    formErrors.familyName ? styles.errorOutline : ""
                  }`}
                  onChange={handleCollectUserInputs}
                />
                {formErrors.familyName && (
                  <span className={styles.error}>
                    <span className="text-red-600">*</span>
                    {formErrors.familyName}
                  </span>
                )}
              </div>
            )}
            <div className="mt-[20px]">
              <p>Birth Star</p>
              <select
                onChange={handleCollectUserInputs}
                value={personData?.birthStar}
                id="birthStar"
                className={`${formErrors.birthStar ? styles.errorOutline : ""}`}
              >
                <option value="" disabled selected>
                  Select Birth Star
                </option>
                {birthStars?.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </select>
              {formErrors.birthStar && (
                <span className={styles.error}>
                  <span className="text-red-600">*</span>
                  {formErrors.birthStar}
                </span>
              )}
            </div>
          </form>
          <div className="mt-[20px] flex gap-[16px] w-[100%] cursor-pointer">
            <button
              className={`text-regular-medium ${styles.button}`}
              onClick={() => {
                setPopup(false);
              }}
            >
              Cancel
            </button>
            <button
              className={`text-regular-medium ${styles.button}`}
              onClick={handleAdd}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default AddPersonPopup;
