import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import Courosel from "./Courosel";
import back from "../../assests/backButtonPromo.svg";
import ReactPlayer from "react-player";
import BackOff from "../../assests/backOff.png";
import PopUp from "./PopUp";
import ServiceForm from "../Share/ServiceForm";
import styles from "./styles/DetailPage.module.css";
import Carousel from "../Carousel/Carousel";
import Play from "../../../src/assests/play.svg";
import GalleryPopup from "../GalleryPopup/GalleryPopup";

function DetailPage({
  promotionData,
  setOpenDetail,
  handleOpenCoupon,
  handleClickCounterPromotion,
  handleOpenRegistration,
  handleProductPurchase,
}) {
  const [form, setForm] = useState(false);
  const [galleryPopup, setGalleryPopup] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);
  window.scrollTo(0, 0);
  //   let data = require("./Media.json");
  const navigate = useNavigate();
  const handleOpenLink = (promotionData) => {
    window.open(promotionData?.showMoreHeader?.url);
    handleClickCounterPromotion(
      "leads",
      promotionData._id,
      "topBannerButton",
      "detailButton",
      promotionData?.contactTitle,
      "leads",
      "promotion"
    );
  };
  const handleOpenForm = (promotionData) => {
    setForm(true);
    handleClickCounterPromotion(
      "leads",
      promotionData._id,
      "topBannerButton",
      "detailButton",
      promotionData?.contactTitle,
      "leads",
      "promotion"
    );
  };
  let colors = promotionData?.themeColors;
  const hanldeOpenCampaign = () => {
    window.open(
      `https://contest.goviral.world/${promotionData?.campaignDetails?.orgDomain}/${promotionData?.campaignDetails?.campaignUniqueText}.html`
    );
  };
  const loadThumbnail = (obj) => {
    if (obj.fileType.startsWith("image")) {
      return obj?.fileUrl;
    } else {
      if (obj?.fileType == "video/youtube") {
        return `https://img.youtube.com/vi/${obj?.fileId}/sddefault.jpg`;
      } else {
        if (obj?.thumbnailUrl) {
          return obj?.thumbnailUrl;
        } else {
          return "";
        }
      }
    }
  };
  const handleClose = () => {
    setGalleryPopup(false);
  };
  const handleGalleryClick = (index) => {
    setGalleryPopup(true);
    setGalleryIndex(index);
  };
  const handleRegisterClick = () => {
    let buttonType = promotionData?.showMoreHeader?.type;
    switch (buttonType) {
      case "link":
        handleOpenLink(promotionData);
        break;
      case "register":
        handleOpenRegistration(promotionData);
        break;
      case "coupon":
        handleOpenCoupon(promotionData);
        break;
      default:
        handleOpenForm(promotionData);
        break;
    }
  };
  const hexToRGBA = (hex, alpha) => {
    const r = parseInt(hex?.slice(1, 3), 16);
    const g = parseInt(hex?.slice(3, 5), 16);
    const b = parseInt(hex?.slice(5, 7), 16);
    if (hex) {
      return `drop-shadow(rgba(${r}, ${g}, ${b}, ${alpha})
       4px 4px 0px)`;
    } else {
      return "";
    }
  };
  return (
    <div className={styles.fullme} style={{ background: colors?.primary }}>
      <div>
        {form === true && (
          <ServiceForm setServiceForm={setForm} promotionData={promotionData} />
        )}
        <div
          className="relative bg-[#000690] mb-[30px]"
          style={{ background: colors?.supporting }}
        >
          <div className="w-full max-w-[1440px] pt-[30px] pb-[20px] md:py-[50px] px-0 md:px-[100px] mx-auto">
            <div className="w-[100%] flex items-center justify-between">
              <div className="h-auto w-[100%] relative flex items-end md:items-center gap-[10px] md:gap-[100px] justify-between md:justify-center px-[20px]">
                <div
                  onClick={() => setOpenDetail(false)}
                  className={styles.backMe}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M6.47173 3.02843C6.50976 3.06643 6.53994 3.11155 6.56052 3.16121C6.58111 3.21087 6.59171 3.2641 6.59171 3.31787C6.59171 3.37163 6.58111 3.42486 6.56052 3.47452C6.53994 3.52419 6.50976 3.5693 6.47173 3.6073L3.48792 6.59059L11.5005 6.59059C11.609 6.59059 11.713 6.63369 11.7897 6.71041C11.8665 6.78713 11.9096 6.89119 11.9096 6.99968C11.9096 7.10818 11.8665 7.21224 11.7897 7.28895C11.713 7.36567 11.609 7.40877 11.5005 7.40877H3.48792L6.47173 10.3921C6.54849 10.4688 6.59161 10.5729 6.59161 10.6815C6.59161 10.7901 6.54849 10.8942 6.47173 10.9709C6.39497 11.0477 6.29085 11.0908 6.1823 11.0908C6.07374 11.0908 5.96963 11.0477 5.89286 10.9709L2.21105 7.28912C2.17301 7.25112 2.14284 7.206 2.12225 7.15634C2.10166 7.10668 2.09106 7.05344 2.09106 6.99968C2.09106 6.94592 2.10166 6.89269 2.12225 6.84303C2.14284 6.79336 2.17301 6.74825 2.21105 6.71025L5.89286 3.02843C5.93086 2.9904 5.97598 2.96022 6.02564 2.93964C6.0753 2.91905 6.12853 2.90845 6.1823 2.90845C6.23606 2.90845 6.28929 2.91905 6.33895 2.93964C6.38862 2.96022 6.43373 2.9904 6.47173 3.02843Z"
                      fill="#F6F6F6"
                    />
                  </svg>
                  <p>back</p>
                </div>
                <div className={styles.headerText}>
                  <h2 style={{ color: colors?.text1 }}>
                    {promotionData?.promotionHeader?.promoterName}
                  </h2>
                  <h3 style={{ color: colors?.text1 }}>
                    {promotionData?.promotionHeader?.promotionName}
                  </h3>
                </div>
                {!promotionData?.campaignPromotion &&
                  promotionData?.showMoreHeader?.display && (
                    <button
                      className={styles.button}
                      onClick={handleRegisterClick}
                      style={{
                        color: colors?.text2,
                        background: colors?.button1,
                        filter: `${hexToRGBA(colors?.button1, 0.6)}`,
                      }}
                    >
                      {promotionData?.showMoreHeader?.name}
                    </button>
                  )}
                {promotionData?.campaignPromotion && (
                  <button
                    className={styles.button}
                    onClick={hanldeOpenCampaign}
                  >
                    {promotionData?.showMoreHeader?.name}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* flexbox of coussrosel */}
        <div className={` ${styles.flexBoxCourosel}`}>
          <div
            className={styles.flexOne}
            style={{ background: colors?.secondary }}
          >
            {/* <Courosel Media={promotionData?.media} /> */}
            <Carousel items={promotionData?.media} />
          </div>
          <div
            className={styles.flextwo}
            style={{ background: colors?.supporting }}
          >
            <div className={styles.detailsOfWhom}>
              <h1
                style={{
                  color: colors?.text1,
                }}
              >
                {promotionData?.promotionHeader?.promotionName}
              </h1>
            </div>
            <p
              style={{
                color: colors?.text1,
              }}
            >
              {promotionData?.details}
            </p>
          </div>
        </div>
      </div>
      {promotionData?.showMoreMedia?.length > 0 && (
        <div className="max-w-[1440px] px-[16px] md:px-[100px] pt-[50px] mx-auto w-full">
          <div className={styles.showMoreMedia}>
            <h1
              style={{
                color: colors?.text1,
              }}
            >
              Gallery
            </h1>
          </div>
          <div className=" w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[30px] cursor-pointer mt-[30px] auto-rows-[300px]">
            {promotionData?.showMoreMedia?.map((obj, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={styles.galleryBorder}
                    onClick={() => handleGalleryClick(index)}
                  >
                    <img
                      className="w-[100%] h-full object-cover"
                      src={loadThumbnail(obj)}
                      alt=""
                    />
                    {obj?.fileType?.startsWith("video") && (
                      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center ">
                        <div className="cursor-pointer rounded-full bg-gray-700 bg-opacity-81 p-[10px]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="32"
                            viewBox="0 0 26 32"
                            fill="none"
                            className="w-[20px] h-[20px]"
                          >
                            <path
                              d="M1.99339 30.8984C2.30915 31.0344 2.66445 31.125 3.01974 31.125C3.57238 31.125 4.12503 30.8984 4.59866 30.5358L24.1382 18.3425L24.3355 18.1159C24.8487 17.5266 25.125 16.8013 25.125 15.9855C25.125 15.1696 24.8487 14.399 24.3355 13.8551L24.1382 13.6284L4.59866 1.34452C3.88814 0.709954 2.8618 0.573977 1.99339 0.981912C1.00652 1.43517 0.375 2.5684 0.375 3.79228V28.0881C0.374935 29.312 1.00652 30.4451 1.99339 30.8984Z"
                              fill="#FFFEFE"
                            />
                          </svg>
                        </div>
                      </div>
                    )}
                  </div>
                  {galleryPopup && (
                    <GalleryPopup
                      data={promotionData?.showMoreMedia}
                      index={galleryIndex}
                      onClose={handleClose}
                    />
                  )}
                </>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default DetailPage;
