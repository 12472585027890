import React from "react";
import styles from "./Styles/CompletionBanner.module.css";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import PopupWrapper from "../../Layout/PopupWrapper";

export default function CompletionBanner({
  price,
  onClick,
  loading,
  buttonColor,
  summaryRef,
}) {
  const viewSummary = () => {
    summaryRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };
  return (
    <PopupWrapper>
      <div className={styles.background}>
        <div className={styles.innerContainer}>
          <div className="flex items-center gap-[9px]">
            {price > 0 && (
              <p className="md:block hidden">
                ₹{price}
                <span
                  onClick={viewSummary}
                  className="ml-[20px] underline text-[#3075FC] text-small-bold"
                >
                  View Summary
                </span>
              </p>
            )}
            {price > 0 && (
              <p className="md:hidden block">
                ₹{price}
                <span
                  onClick={viewSummary}
                  className="ml-[20px] underline text-[#3075FC] text-small-bold"
                >
                  View Summary
                </span>
              </p>
            )}
          </div>

          <button onClick={onClick} disabled={loading} style={buttonColor()}>
            {loading ? <LoadingCircle /> : "Pay and Submit"}
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
}
