import React from "react";
import ReactPlayer from "react-player";
import styles from "./Template6.module.css";

export default function Template6({
  carousel,
  handleOpenForm,
  handleLinkClick,
}) {
  return (
    <div
      className="  py-[30px]"
      style={{ background: carousel?.bgColor, color: carousel?.textColor }}
    >
      <div className="w-full max-w-[1440px] px-0 md:px-[8.3%] mx-auto">
        {carousel.heading && (
          <div className="flex items-center mb-[16px] w-full">
            <p
              className={` text-[22px] md:text-[32px] leading-[38px] w-fit shrink-0  ${
                styles.heading
              }
        ${
          carousel.items.length < 5
            ? "md:text-center md:pl-[0px] pl-[16px] "
            : "md:pl-[100px] pl-[16px]"
        }`}
              style={{ fontFamily: carousel?.font }}
            >
              {carousel.heading}
            </p>
            <div
              className="w-full h-[0.5px] mx-[16px] border-0 border-none opacity-50"
              style={{ background: carousel?.textColor }}
            />
          </div>
        )}
        <div
          className={`flex box  gap-[16px] pr-[16px] overflow-x-auto  ${
            carousel.items.length < 5
              ? "md:justify-center md:pl-[0px] pl-[16px]"
              : "md:pl-[100px] pl-[16px]"
          }`}
        >
          {carousel &&
            carousel.items &&
            carousel.items.map((add) => {
              return (
                <>
                  <div
                    className="md:w-[264px] w-[264px] flex flex-col mb-[16px]"
                    style={{ background: add?.bgColor }}
                  >
                    {add?.fileType?.startsWith("image") && (
                      <img
                        src={add.fileUrl}
                        alt=""
                        className="min-w-[264px] md:w-[264px] w-[264px] md:h-[324px]  h-[324px] object-cover "
                      />
                    )}
                    {add.fileType?.startsWith("video") && (
                      <div className="min-w-[264px] md:w-[264px] w-[264px] md:h-[324px]  h-[324px] object-cover ">
                        <ReactPlayer
                          config={{
                            file: {
                              attributes: {
                                controlsList: "nodownload",
                              },
                            },
                            youtube: { playerVars: { disablekb: 1 } },
                          }}
                          // playing={true}
                          controls={true}
                          width="100%"
                          light={add?.thumbnailUrl ? add?.thumbnailUrl : true}
                          height="100%"
                          url={add?.fileUrl}
                          className={styles.reactPlayer}
                        />
                      </div>
                    )}
                    <div className="p-[16px]">
                      {add?.description && (
                        <p
                          className={`text_Ell ${styles.description}`}
                          style={{
                            color: add?.textColor,
                            fontFamily: carousel?.font,
                          }}
                        >
                          {add.description}
                        </p>
                      )}
                      <p
                        className="mb-[12px] text-[12px] "
                        style={{
                          color: add?.textColor,
                          fontFamily: carousel?.font,
                        }}
                      >
                        {add.tagLine}
                      </p>
                      {add?.shopbuttton && (
                        <button
                          className="px-[16px] py-[8px] text-[12px] font-medium rounded-[30px] hover:cursor-pointer "
                          style={{
                            backgroundColor: add?.btnColor,
                            color: add?.btnTextColor,
                          }}
                          onClick={() => {
                            if (add.goto === "form") {
                              handleOpenForm(add);
                            } else {
                              handleLinkClick(add);
                            }
                          }}
                        >
                          {add.shopbuttton}
                        </button>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
}
