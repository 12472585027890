import React, { useContext, useEffect, useState } from "react";
import "./Share.css";
import close from "../../assests/closebuttonblack.png";
import ServiceTerms from "./ServiceTerms";
import RegisterFormInput from "./RegisterFormInput";
import Context from "../../context/Context";

const RegistrationForm = ({ service, setServiceForm, promotionData }) => {
  const { tinyUrlFromMetaData } = useContext(Context);
  const tinyUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");
  let promotion = tinyUrl.startsWith("pro");

  const [showTerms, setShowTerms] = useState(false);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    // w-[100%] h-[100%] absolute backdrop flex flex-col items-center justify-center
    <div className="w-[100%] h-[100%] fixed top-0 flex flex-col items-center justify-center serviceformbg backdrop-blur-xl z-[50] overflow-x-hidden overflow-y-auto no-scrollbar">
      <div className=" absolute top-0 px-[3%] w-full">
        <div className="h-auto  box w-full w-full max-w-[800px]  bg-[#FFFFFF] rounded-[20px] my-10 mx-auto">
          <div className="w-[100%] h-[30%] relative rounded-t-[20px] overflow-hidden">
            {!promotionData?.showMoreHeader?.directReg && (
              <div
                className="absolute top-2 right-2 rounded-full bg-white h-[48px] w-[48px] flex justify-center items-center hover:cursor-pointer"
                onClick={() => setServiceForm(false)}
              >
                <img src={close} alt="" className="h-[16px] w-[16px] " />
              </div>
            )}
            <img
              src={
                promotionData?.loginBanner ||
                (promotion
                  ? promotionData?.bannerWeb
                  : service?.fileType?.startsWith("image")
                  ? service?.fileUrl
                  : service?.thumbnailUrl)
              }
              alt=""
              className="w-[100%] h-[30vh] md:h-[40vh] object-contain"
            />
            <div
              className="w-[100%] h-[150px]  serviceformbanner flex flex-col items-center text-center justify-evenly px-[5%] py-[2%]"
              style={{
                background: promotionData?.themeColors?.secondary,
              }}
            >
              <p
                className="serviceformheading text-[21px] md:text-[32px]"
                style={{
                  color: promotionData?.themeColors?.text1,
                }}
              >
                {promotion
                  ? promotionData?.promotionHeader?.promotionName
                  : service?.title}
              </p>
            </div>
          </div>
          {promotionData?.showMoreHeader?.regOpen == false ? (
            <pre className="leadcardtext text-black text-center py-[50px] px-[20px]">
              {promotionData?.showMoreHeader?.regText ||
                "Registration will open soon"}
            </pre>
          ) : (
            <RegisterFormInput
              setServiceForm={setServiceForm}
              mail={service?.email}
              setShowTerms={setShowTerms}
              promotionData={promotionData}
              service={service}
            />
          )}
        </div>
        {showTerms && <ServiceTerms setShowTerms={setShowTerms} />}
      </div>
    </div>
  );
};

export default RegistrationForm;
