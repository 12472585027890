import React, { useContext, useEffect, useState } from "react";
import styles from "../PromotionBanner/PromotionBanner.module.css";
import ReactPlayer from "react-player";
import heart from "../../assests/heart.svg";
import * as _ from "lodash";
import whiteHeart from "../../assests/whiteHeart.svg";
import Play from "../../../src/assests/play.svg";
import { useUserAuth } from "../../context/UserAuthContext";
import DanceTemplate from "./DanceTemplate";
import CollegeProTemlate from "./CollegeProTemlate/CollegeProTemlate";
import Context from "../../context/Context";

export default function NormalPromotion({
  promotionDataJson,
  setShare,
  setOpenDetail,
  likeState,
  likeCount,
  handleLogin,
  likeLoading,
  likeHandle,
  handleOpenForm,
  handleOpenLink,
  handleOpenCoupon,
  handleProductPurchase,
  handleOpenRegistration,
}) {
  const { currentUser, setReqForLike } = useUserAuth();
  const { gallaryClick, setGallaryClick } = useContext(Context);
  const [play, setPlay] = useState(false);

  const formImageRender = (innerWidth) => {
    if (
      !_.isEmpty(
        promotionDataJson?.bannerMobile || promotionDataJson?.bannerWeb
      )
    ) {
      if (innerWidth <= 600) {
        return promotionDataJson?.bannerMobile;
      } else {
        return promotionDataJson?.bannerWeb;
      }
    } else {
      return promotionDataJson?.fileUrl;
    }
  };
  const handleRegisterClick = () => {
    let buttonType = promotionDataJson?.showMoreHeader?.type;

    switch (buttonType) {
      case "link":
        handleOpenLink(promotionDataJson);
        break;
      case "register":
        handleOpenRegistration(promotionDataJson);
        break;
      case "coupon":
        handleOpenCoupon(promotionDataJson);
        break;
      case "product_purchase":
        handleProductPurchase(promotionDataJson);
        break;
      default:
        handleOpenForm(promotionDataJson);
        break;
    }
  };
  useEffect(() => {
    if (promotionDataJson?.showMoreHeader?.directReg) {
      handleRegisterClick();
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(gallaryClick)) {
      handleRegisterClick();
      // setGallaryClick({});
    }
  }, [gallaryClick]);

  const hexToRGBA = (hex, alpha) => {
    const r = parseInt(hex?.slice(1, 3), 16);
    const g = parseInt(hex?.slice(3, 5), 16);
    const b = parseInt(hex?.slice(5, 7), 16);
    if (hex) {
      return `drop-shadow(rgba(${r}, ${g}, ${b}, ${alpha})
       4px 4px 0px)`;
    } else {
      return "";
    }
  };
  let colors = promotionDataJson?.themeColors;
  let type = promotionDataJson?.templateType;
  let promotionPageJSX;
  // switch (promotionDataJson?.templateType) {
  switch (type) {
    case "DANCE":
      promotionPageJSX = (
        <DanceTemplate
          promotionDataJson={promotionDataJson}
          setShare={setShare}
          setOpenDetail={setOpenDetail}
          likeState={likeState}
          likeCount={likeCount}
          handleLogin={handleLogin}
          likeLoading={likeLoading}
          likeHandle={likeHandle}
          handleOpenForm={handleOpenForm}
          handleOpenLink={handleOpenLink}
        />
      );
      break;
    case "DEFAULT":
      promotionPageJSX = (
        <>
          <div
            className="w-full max-w-[1440px] px-0 md:px-[8.3%] mx-auto"
            style={{ background: colors?.primary }}
          >
            <div className="md:h-[90vh] max-h-[790px] md:max-h-[686px]  relative overflow-hidden">
              <div className={styles.layout}>
                <div className={styles.left}>
                  <div className="flex flex-col gap-[28px]">
                    <div className="flex items-center gap-[10px] md:gap-[6.3%]">
                      {promotionDataJson?.promotionHeader?.promoterLogo && (
                        <img
                          src={promotionDataJson?.promotionHeader?.promoterLogo}
                          alt=""
                          className={styles.logo}
                        />
                      )}
                      <div>
                        <p
                          className={`text-[#CF0] ${styles.callout1}`}
                          style={{ color: colors?.text1 }}
                        >
                          {promotionDataJson?.promotionHeader?.promoterName}
                        </p>
                        <p
                          className={`md:mt-[7px] ${styles.subtextMedium}`}
                          style={{ color: colors?.text1 }}
                        >
                          {promotionDataJson?.promotionHeader?.type}
                        </p>
                      </div>

                      {/* <img
                  src={shareWithoutBg}
                  alt=""
                  className="w-[30px] h-[30px] hover:cursor-pointer z-[1]  ml-auto block md:hidden"
                  onClick={() => setShare(true)}
                /> */}
                      <button
                        className={styles.sharebutton}
                        onClick={() => setShare(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="19"
                          viewBox="0 0 26 19"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.8299 16.1295L24.5203 9.73585C24.8239 9.4898 25 9.12148 25 8.73259C25 8.3437 24.8239 7.97556 24.5203 7.72933L16.8299 1.33572C16.4197 0.987929 15.8456 0.902652 15.3507 1.11607C14.8557 1.3295 14.5269 1.80423 14.5036 2.33898V4.86074C3.7069 3.00555 1 12.5892 1 18C3.50461 13.8781 9.99499 6.40639 14.5036 12.5892V15.1177C14.5239 15.6541 14.8515 16.1317 15.3469 16.3472C15.8425 16.5626 16.4185 16.4781 16.8299 16.1295Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <p className="text-regular-medium text-white ">Share</p>
                      </button>
                    </div>
                    <div className="flex md:flex-col gap-[28px] md:items-start items-center">
                      <h1
                        className="text-white break-all sm:break-normal"
                        style={{ color: colors?.text1 }}
                      >
                        {promotionDataJson?.promotionHeader?.promotionName}
                      </h1>
                      {promotionDataJson?.showMoreHeader?.display && (
                        <button
                          onClick={handleRegisterClick}
                          className={`ml-0 md:ml-[0px] text-black ${styles.registerButton} ${styles.buttonText}`}
                          style={{
                            color: colors?.text2,
                            background: colors?.button1,
                            filter: hexToRGBA(colors?.button1, 0.6),
                          }}
                        >
                          {promotionDataJson?.showMoreHeader?.name}
                        </button>
                      )}
                      {/* {promotionDataJson?.campaignPromotion && (
                    <button
                      onClick={hanldeOpenCampaign}
                      className={`ml-0 md:ml-[0px] text-black whitespace-nowrap ${styles.registerButton} ${styles.buttonText}`}
                    >
                      {promotionDataJson?.showMoreHeader?.name}
                    </button>
                  )} */}
                    </div>
                  </div>
                  {/* <div className="hidden md:block">
              <p
                className={`text_Ell mb-[4px] text-[#F6F6F6] ${styles.moreButtonDescription}`}
              >
                {promotionDataJson?.aboutPromotion}
              </p>
              <button
                className={styles.moreButton}
                onClick={() => setOpenDetail(true)}
              >
                More Details
              </button>
            </div> */}
                </div>
                <div
                  className={styles.right}
                  style={{ background: colors?.secondary }}
                >
                  {promotionDataJson?.topMoreDetails && (
                    <button
                      className={`${styles.buttonText} ${styles.moreDetailsOnTop} px-[20px] md:px-[39px] md:py-[12px] py-[7px]  rounded-[5px] absolute top-[30%] left-[30%]  md:hidden`}
                      onClick={() => setOpenDetail(true)}
                      style={{ background: colors?.buttton2 }}
                    >
                      <p className="h-[24px] md:h-[32px] flex items-center justify-center">
                        View Details
                      </p>
                    </button>
                  )}
                  <div
                    className="bg-[#001E4B] w-full h-[84%] md:ml-[-3%] flex justify-center"
                    style={{
                      background: colors?.supporting,
                      height:
                        promotionDataJson?.hideLike &&
                        promotionDataJson?.hideViewDetails &&
                        "100%",
                    }}
                  >
                    {promotionDataJson?.fileType?.startsWith("video") ? (
                      (!play && (
                        <>
                          <div
                            onClick={() => setPlay(true)}
                            className="flex justify-center items-center  overflow-hidden"
                          >
                            <img
                              src={promotionDataJson?.thumbReplaceUrl}
                              alt=""
                              className="md:h-full h-auto w-full md:w-auto  object-contain "
                            />
                            <img
                              className="absolute h-[60px] w-[60px] cursor-pointer"
                              src={Play}
                              alt=""
                            />
                          </div>
                        </>
                      )) ||
                      (play && (
                        <div className="flex justify-center items-center w-[100%] md:px-[100px] h-[100%] overflow-hidden">
                          <ReactPlayer
                            config={{
                              file: {
                                attributes: { controlsList: "nodownload" },
                              },
                            }}
                            playing={true}
                            controls={true}
                            width="100%"
                            height="100%"
                            playIcon={promotionDataJson?.thumbReplaceUrl}
                            url={promotionDataJson?.fileUrl}
                            className="object-contain height-[100%] abovethousand"
                          />
                          <ReactPlayer
                            config={{
                              file: {
                                attributes: { controlsList: "nodownload" },
                              },
                            }}
                            playing={true}
                            controls={true}
                            width="100%"
                            height="100%"
                            playIcon={promotionDataJson?.thumbReplaceUrl}
                            url={promotionDataJson?.fileUrl}
                            className="object-contain height-[100%] belowthousand"
                          />
                        </div>
                      ))
                    ) : (
                      <img
                        src={formImageRender(window.innerWidth)}
                        className="md:h-full h-auto w-full md:w-auto  object-contain "
                      />
                    )}
                  </div>
                  {(!promotionDataJson?.hideLike ||
                    !promotionDataJson?.hideViewDetails) && (
                    <div className="h-fit pt-[4%] px-[3%] flex gap-[3%] items-center justify-end">
                      {!promotionDataJson?.hideLike && (
                        <button
                          className={`${
                            styles.buttonText
                          }  flex gap-[16px] rounded-[5px] items-center justify-center px-[20px] md:px-[39px] md:min-w-[190px] min-w-[130px] md:py-[12px] py-[7px] transition duration-300 ease-in ${
                            likeState
                              ? "text-white bg-[#FF7F5B]"
                              : "text-black bg-[#CF0]"
                          }`}
                          onClick={
                            currentUser
                              ? () => {
                                  setReqForLike(true);
                                  likeHandle();
                                }
                              : () => handleLogin()
                          }
                          disabled={likeLoading}
                          style={
                            likeState
                              ? {
                                  background: colors?.button1,
                                  color: colors?.text2,
                                }
                              : {
                                  background: colors?.buttton2,
                                  color: colors?.text1,
                                }
                          }
                        >
                          {!likeLoading && (
                            <>
                              <span className="flex gap-[5px] items-center">
                                <p className={styles.callout1}>{likeCount} </p>

                                <img
                                  src={likeState ? whiteHeart : heart}
                                  className="w-[24px] h-[24px] md:w-[32px] md:h-[32px]"
                                />
                              </span>
                              <span>{likeState ? "Liked" : "Like"}</span>
                            </>
                          )}
                          {likeLoading && <span className={styles.loader} />}
                        </button>
                      )}
                      {/* <button
                          onClick={() => setShare(true)}
                          className={`${styles.buttonText} px-[20px] md:px-[39px] md:py-[12px] py-[7px] bg-[#00FFF0] text-black rounded-[5px]`}
                            >
                            <p className="h-[24px] md:h-[32px] flex items-center justify-center">
                           Share
                           </p>
                          </button> */}
                      {promotionDataJson?.topMoreDetails &&
                        !promotionDataJson?.hideViewDetails && (
                          <button
                            className={`${styles.buttonText} px-[20px] md:px-[39px] md:py-[12px] py-[7px] bg-[#00FFF0] text-black rounded-[5px] hidden md:block`}
                            onClick={() => setOpenDetail(true)}
                            style={{
                              background: colors?.buttton2,
                              color: colors?.text1,
                            }}
                          >
                            <p className="h-[24px] md:h-[32px] flex items-center justify-center">
                              View Details
                            </p>
                          </button>
                        )}
                      {!promotionDataJson?.topMoreDetails &&
                        !promotionDataJson?.hideViewDetails && (
                          <button
                            className={`${styles.buttonText} px-[20px] md:px-[39px] md:py-[12px] py-[7px] bg-[#00FFF0] text-black rounded-[5px] `}
                            onClick={() => setOpenDetail(true)}
                            style={{
                              background: colors?.buttton2,
                              color: colors?.text1,
                            }}
                          >
                            <p className="h-[24px] md:h-[32px] flex items-center justify-center">
                              View Details
                            </p>
                          </button>
                        )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case "COLLEGE":
      promotionPageJSX = (
        <CollegeProTemlate
          promotionDataJson={promotionDataJson}
          setShare={setShare}
          setOpenDetail={setOpenDetail}
          likeState={likeState}
          likeCount={likeCount}
          handleLogin={handleLogin}
          likeLoading={likeLoading}
          likeHandle={likeHandle}
          handleOpenForm={handleOpenForm}
          handleOpenLink={handleOpenLink}
          handleRegisterClick={handleRegisterClick}
        />
      );
      break;
    default:
      promotionPageJSX = (
        <>
          <div className="w-full max-w-[1440px] px-0 md:px-[8.3%] mx-auto">
            <div className="md:h-[90vh] max-h-[790px] md:max-h-[686px]  relative overflow-hidden">
              <div className={styles.layout}>
                <div className={styles.left}>
                  <div className="flex flex-col gap-[28px]">
                    <div className="flex items-center gap-[10px] md:gap-[6.3%]">
                      {promotionDataJson?.promotionHeader?.promoterLogo && (
                        <img
                          src={promotionDataJson?.promotionHeader?.promoterLogo}
                          alt=""
                          className={styles.logo}
                        />
                      )}
                      <div>
                        <p className={`text-[#CF0] ${styles.callout1}`}>
                          {promotionDataJson?.promotionHeader?.promoterName}
                        </p>
                        <p className={`md:mt-[7px] ${styles.subtextMedium}`}>
                          {promotionDataJson?.promotionHeader?.type}
                        </p>
                      </div>

                      {/* <img
                src={shareWithoutBg}
                alt=""
                className="w-[30px] h-[30px] hover:cursor-pointer z-[1]  ml-auto block md:hidden"
                onClick={() => setShare(true)}
              /> */}
                      <button
                        className={styles.sharebutton}
                        onClick={() => setShare(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="19"
                          viewBox="0 0 26 19"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.8299 16.1295L24.5203 9.73585C24.8239 9.4898 25 9.12148 25 8.73259C25 8.3437 24.8239 7.97556 24.5203 7.72933L16.8299 1.33572C16.4197 0.987929 15.8456 0.902652 15.3507 1.11607C14.8557 1.3295 14.5269 1.80423 14.5036 2.33898V4.86074C3.7069 3.00555 1 12.5892 1 18C3.50461 13.8781 9.99499 6.40639 14.5036 12.5892V15.1177C14.5239 15.6541 14.8515 16.1317 15.3469 16.3472C15.8425 16.5626 16.4185 16.4781 16.8299 16.1295Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <p className="text-regular-medium text-white ">Share</p>
                      </button>
                    </div>
                    <div className="flex md:flex-col gap-[28px] md:items-start items-center">
                      <h1 className="text-white">
                        {promotionDataJson?.promotionHeader?.promotionName}
                      </h1>
                      {!promotionDataJson?.campaignPromotion &&
                        promotionDataJson?.showMoreHeader?.display && (
                          <button
                            onClick={handleRegisterClick}
                            className={`ml-0 md:ml-[0px] text-black ${styles.registerButton} ${styles.buttonText}`}
                          >
                            {promotionDataJson?.showMoreHeader?.name}
                          </button>
                        )}
                      {/* {promotionDataJson?.campaignPromotion && (
                  <button
                    onClick={hanldeOpenCampaign}
                    className={`ml-0 md:ml-[0px] text-black whitespace-nowrap ${styles.registerButton} ${styles.buttonText}`}
                  >
                    {promotionDataJson?.showMoreHeader?.name}
                  </button>
                )} */}
                    </div>
                  </div>
                  {/* <div className="hidden md:block">
            <p
              className={`text_Ell mb-[4px] text-[#F6F6F6] ${styles.moreButtonDescription}`}
            >
              {promotionDataJson?.aboutPromotion}
            </p>
            <button
              className={styles.moreButton}
              onClick={() => setOpenDetail(true)}
            >
              More Details
            </button>
          </div> */}
                </div>
                <div className={styles.right}>
                  {promotionDataJson?.topMoreDetails && (
                    <button
                      className={`${styles.buttonText} ${styles.moreDetailsOnTop} px-[20px] md:px-[39px] md:py-[12px] py-[7px]  rounded-[5px] absolute top-[30%] left-[30%]  md:hidden`}
                      onClick={() => setOpenDetail(true)}
                    >
                      <p className="h-[24px] md:h-[32px] flex items-center justify-center">
                        View Details
                      </p>
                    </button>
                  )}
                  <div className="bg-[#001E4B] w-full h-[84%] md:ml-[-3%] flex justify-center">
                    {promotionDataJson?.fileType?.startsWith("video") ? (
                      (!play && (
                        <>
                          <div
                            onClick={() => setPlay(true)}
                            className="flex justify-center items-center  overflow-hidden"
                          >
                            <img
                              src={promotionDataJson?.thumbReplaceUrl}
                              alt=""
                              className="md:h-full h-auto w-full md:w-auto  object-contain "
                            />
                            <img
                              className="absolute h-[60px] w-[60px] cursor-pointer"
                              src={Play}
                              alt=""
                            />
                          </div>
                        </>
                      )) ||
                      (play && (
                        <div className="flex justify-center items-center w-[100%] md:px-[100px] h-[100%] overflow-hidden">
                          <ReactPlayer
                            config={{
                              file: {
                                attributes: { controlsList: "nodownload" },
                              },
                            }}
                            playing={true}
                            controls={true}
                            width="100%"
                            height="100%"
                            playIcon={promotionDataJson?.thumbReplaceUrl}
                            url={promotionDataJson?.fileUrl}
                            className="object-contain height-[100%] abovethousand"
                          />
                          <ReactPlayer
                            config={{
                              file: {
                                attributes: { controlsList: "nodownload" },
                              },
                            }}
                            playing={true}
                            controls={true}
                            width="100%"
                            height="100%"
                            playIcon={promotionDataJson?.thumbReplaceUrl}
                            url={promotionDataJson?.fileUrl}
                            className="object-contain height-[100%] belowthousand"
                          />
                        </div>
                      ))
                    ) : (
                      <img
                        src={formImageRender(window.innerWidth)}
                        className="md:h-full h-auto w-full md:w-auto  object-contain "
                      />
                    )}
                  </div>
                  <div className="h-fit pt-[4%] px-[3%] flex gap-[3%] items-center justify-end">
                    {!promotionDataJson?.hideLike && (
                      <button
                        className={`${
                          styles.buttonText
                        }  flex gap-[16px] rounded-[5px] items-center justify-center px-[20px] md:px-[39px] md:min-w-[190px] min-w-[130px] md:py-[12px] py-[7px] transition duration-300 ease-in ${
                          likeState
                            ? "text-white bg-[#FF7F5B]"
                            : "text-black bg-[#CF0]"
                        }`}
                        onClick={
                          currentUser
                            ? () => {
                                setReqForLike(true);
                                likeHandle();
                              }
                            : () => handleLogin()
                        }
                        disabled={likeLoading}
                      >
                        {!likeLoading && (
                          <>
                            <span className="flex gap-[5px] items-center">
                              <p className={styles.callout1}>{likeCount} </p>

                              <img
                                src={likeState ? whiteHeart : heart}
                                className="w-[24px] h-[24px] md:w-[32px] md:h-[32px]"
                              />
                            </span>
                            <span>{likeState ? "Liked" : "Like"}</span>
                          </>
                        )}
                        {likeLoading && <span className={styles.loader} />}
                      </button>
                    )}
                    {/* <button
              onClick={() => setShare(true)}
              className={`${styles.buttonText} px-[20px] md:px-[39px] md:py-[12px] py-[7px] bg-[#00FFF0] text-black rounded-[5px]`}
            >
              <p className="h-[24px] md:h-[32px] flex items-center justify-center">
                Share
              </p>
            </button> */}
                    {promotionDataJson?.topMoreDetails &&
                      !promotionDataJson?.hideViewDetails && (
                        <button
                          className={`${styles.buttonText} px-[20px] md:px-[39px] md:py-[12px] py-[7px] bg-[#00FFF0] text-black rounded-[5px] hidden md:block`}
                          onClick={() => setOpenDetail(true)}
                        >
                          <p className="h-[24px] md:h-[32px] flex items-center justify-center">
                            View Details
                          </p>
                        </button>
                      )}
                    {!promotionDataJson?.topMoreDetails &&
                      !promotionDataJson?.hideViewDetails && (
                        <button
                          className={`${styles.buttonText} px-[20px] md:px-[39px] md:py-[12px] py-[7px] bg-[#00FFF0] text-black rounded-[5px] `}
                          onClick={() => setOpenDetail(true)}
                        >
                          <p className="h-[24px] md:h-[32px] flex items-center justify-center">
                            View Details
                          </p>
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
  }
  return <>{promotionPageJSX}</>;
}
