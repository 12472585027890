import React from "react";
import styles from "../Share/Share.module.css";
import "./Share.css";
import axios from "axios";
import Context from "../../context/Context";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import { useContext, useState, useEffect } from "react";
import { ViewReporter } from "../../context/apiCalls/apiCalls";
import ReactPlayer from "react-player";
import down from "./../../assests/noContest/downArrow.png";
import like from "./../../assests/noContest/like.png";
import whitelike from "./../../assests/whitelikehand.svg";
import whtshare from "./../../assests/whtshare.png";
import eye from "./../../assests/viewseye.svg";
import Share from "../../../src/assests/shareblack.svg";
import Play from "../../../src/assests/play.svg";
import arrow from "../../../src/assests/smallrightarrow.svg";
import ServiceForm from "./ServiceForm";
import ShareInShare from "./ShareInShare";
import { randomizeColor } from "../utils/randomColorPicker";
import LikesList from "./LikesList";
import Lottie from "../../Components/lottie/finalupload/finalUploadloading";
import * as _ from "lodash";
import EntryCarousel from "../EntryCarousel/EntryCarousel";
import useGetUserProfileImage from "../../hooks/useGetUserProfileImage";
import user from "../../assests/user.svg";
import ProfileDropDown from "../ProfileDropDown/ProfileDropDown";
import ParticipationCertificate from "../ParticipationCertificate/ParticipationCertificate";
import certificateImage from "../../assests/certificate.png";
import LikePopup from "../LikePopup/LikePopup";
import AudioPlayerWithoutDownload from "../AudioPlayerWithoutDownload/AudioPlayerWithoutDownload";
import PageNotFound from "../PageNotFound/PageNotFound";
import couponButton from "../../assests/couponButton.png";
import WhatsappNumberVerfication from "../WhatsappNumberVerfication/WhatsappNumberVerfication";
import WhatsappLike from "../WhatsappLike/WhatsappLike";

let cancelTokenSource = null;
const CarouselDot = ({ active, onClick, id }) => {
  const dotClass = `carousel-dot ${active ? "carousel-dot-active" : ""}`;
  return <div className={dotClass} onClick={onClick} id={id} />;
};
const MoreAbout = ({
  serviceForm,
  setServiceForm,
  service,
  setService,
  campaignTemplateData,
  setCampaignTemplateData,
  shareFullData,
  setShareFullData,
  setShowCouponCart,
  loadCmpInfluencerAdsDetails,
  coupons,
  ads,
}) => {
  useEffect(() => {
    verifyUser();
  }, []);
  const [currentItem, setCurrentItem] = useState(0);
  const [whatsappLikePopup, setWhatsappLikePopup] = useState(false);
  const [likeState, setLikeState] = useState(false);
  const [play, setPlay] = useState(false);
  const [formAttributes, setFormAttributes] = useState([]);
  const {
    currentUser,
    setPreviousLocation,
    setShowLogin,
    reqForLike,
    setReqForLike,
    setHideWhatsapp,
    userDetailsFromApi,
    whatsappVerified,
    verificationPopup,
    setVerificationPopup,
  } = useUserAuth();
  const {
    verifyUser,
    userIdToken,
    setWhatsUpLink,
    setParticipantName,
    selectedCoupons,
    setCampaignInfId,
  } = useContext(Context);
  const [likePopup, setLikePopup] = useState(false);
  const [likeMessage, setLikeMessage] = useState("");

  const tinyUrl = window.location.pathname
    .split("/")[1]
    ?.replace(/\.html$/, "");
  const orgId = tinyUrl.split("o").pop().split("cp")[0];
  const campaignId = tinyUrl.split("cp").pop().split("cn")[0];
  let contestId = tinyUrl.split("cn").pop().split("e")[0];
  let entryId = tinyUrl.split("e").pop();
  useEffect(() => {
    loadCampaignTemplateData(orgId, campaignId);
  }, [currentUser]);

  const [serviceDetails, setServiceDetails] = useState([]);
  const token = userIdToken || currentUser?.accessToken;
  // let orgDomain = shareFullData?.orgDomain
  const [showCertificate, setShowCertificate] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [likeLoading, setLikeLoading] = useState(false);
  const [likeCount, setLikeCount] = useState();
  const [showLikes, setShowLikes] = useState(false);
  const [share, setShare] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [contestMetaData, setContestMetaData] = useState({});
  const [entryRemoved, setEntryRemoved] = useState(false);
  const loadDetails = async (loadEntryFromJson) => {
    try {
      setLoading(true);
      let url = "";
      if (loadEntryFromJson) {
        url = `https://storage.googleapis.com/campaign_entry_jsons/${orgId}/${campaignId}/${contestId}/${entryId}/entryDetails.json`;
      } else {
        url = `${process.env.REACT_APP_SHAREPAGE_TINYURL}/${tinyUrl}`;
      }
      const data = await axios.get(url);
      let sharedData = {};
      if (loadEntryFromJson) {
        sharedData = data?.data;
        getLikeCount();
      } else {
        sharedData = data?.data?.data[0];
        setLikeCount(sharedData?.likesCount);
      }
      setShareFullData(sharedData);
      setLoading(false);
      if (sharedData?.infId) {
        setCampaignInfId(sharedData?.infId);
        loadCmpInfluencerAdsDetails(
          sharedData?.infId,
          sharedData?.orgId,
          sharedData?.campaignId
        );
      }
      if (
        !(
          sharedData?.status == "ADMIN_PUBLISHED" ||
          sharedData?.status == "EDITED"
        )
      ) {
        setEntryRemoved(true);
      }
      setParticipantName(sharedData?.participantName);
      loadFormAttributes(orgId, campaignId, contestId);
      loadContestDetails(orgId, campaignId, contestId);

      if (token) {
        likeCheck();
      }
      if (token) {
        ViewReporter(
          sharedData?.campaignId,
          sharedData?.contestId,
          sharedData?._id,
          token,
          sharedData?.orgId
        );
        // }
      }
    } catch (err) {
      setLoading(false);
      setEntryRemoved(true);
      console.log(err.message);
    }
  };
  useEffect(() => {
    likeCheck();
  }, [whatsappVerified]);
  const likeCheck = async () => {
    try {
      setLikeLoading(true);
      let config1 = {
        method: "post",
        url: `${process.env.REACT_APP_LIKECHECK}/${orgId}/${campaignId}/${contestId}/${entryId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response1 = await axios(config1);
      setLikeState(response1?.data?.liked);
      if (reqForLike) {
        LikeHandle(response1?.data?.liked, "signup");
        setReqForLike(false);
      }
      setLikeLoading(false);
    } catch (err) {
      console.log(err.message);
    }
  };
  const getLikeCount = async () => {
    setLikeLoading(true);
    try {
      let config1 = {
        method: "get",
        url: `${process.env.REACT_APP_LIKECOUNT}/${tinyUrl}`,
      };
      const response1 = await axios(config1);
      setLikeCount(response1?.data?.data?.likesCount);
      setLikeLoading(false);
    } catch (err) {
      console.log(err.message);
    }
  };
  const loadFormAttributes = async (orgId, campaignId, contestId) => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_FORM_ATTRIBUTES}/${orgId}/${campaignId}/${contestId}/formAttributes.json`
      );
      setFormAttributes(data.data);
    } catch (error) {
      console.log(error.message);
      //navigate("/error")
    }
  };
  const loadContestDetails = async (orgId, campaignId, contestId) => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_CONTEST_DATA}/${orgId}/${campaignId}/${contestId}/metaData.json`
      );
      setContestMetaData(data?.data);
    } catch (error) {
      console.log(error.message);
      //navigate("/error")
    }
  };
  const loadCampaignTemplateData = async (orgId, campaignId) => {
    try {
      const data = await axios.get(
        `https://storage.googleapis.com/goviral-share/${orgId}/${campaignId}/sharePageDetails/shareDetails.json`
      );
      setCampaignTemplateData(data.data);
      loadDetails(data?.data?.loadEntryFromJson);
      setHideWhatsapp(data?.data?.hideWhatsApp);
    } catch (error) {
      console.log(error.message);
      loadDetails();
      //navigate("/error")
    }
  };

  setWhatsUpLink(serviceDetails?.whatsUpUrl);
  const LikeHandle = async (currentLikeState, source) => {
    let phone = userDetailsFromApi?.phoneNumber;
    let regions = "";
    let verified = false;
    if (!_.isEmpty(campaignTemplateData?.likeAllowedCountries) && phone) {
      campaignTemplateData?.likeAllowedCountries?.forEach((element, index) => {
        if (phone?.startsWith(element?.code)) {
          verified = true;
        }
        regions += element?.country;
        if (index < campaignTemplateData?.likeAllowedCountries.length - 1) {
          regions += ", ";
        }
      });
      if (!verified) {
        setLikePopup(true);
        setLikeMessage(
          `Votes for this contest are limited to mobile numbers under the region(s) - "${regions}"`
        );
        return;
      }
    }
    if (contestMetaData?.likeClosed) {
      setLikeMessage(
        `Unfortunately, we are unable to accept any more votes at this time as the contest has officially concluded.`
      );
      setLikePopup(true);
      return;
    }

    if (source == "signup") {
      if (currentLikeState) {
        setLikeState(true);
      } else {
        setLikeCount((prevState) => prevState + 1);
        setLikeState(true);
        likeApi(currentLikeState, source);
      }
    } else {
      if (currentLikeState) {
        setLikeMessage(`You've already voted for this post. 
Thank you for your support!`);
        setLikePopup(true);
      } else {
        if (!whatsappVerified) {
          setVerificationPopup(true);
          return;
        }
        setLikeCount((prevState) => prevState + 1);
        setLikeState(true);
        likeApi(currentLikeState, source);
      }
    }

    // likeApi(currentLikeState, source);
  };
  const likeApi = async (currentLikeState, source) => {
    // Cancel the previous request (if any)
    if (cancelTokenSource !== null) {
      cancelTokenSource.cancel("Request canceled due to button click.");
    }

    // Create a new cancel token source

    cancelTokenSource = axios.CancelToken.source();
    try {
      let url = "";
      if (source == "signup") {
        url = `${process.env.REACT_APP_LIKE}/${tinyUrl}?command=like`;
      } else {
        if (currentLikeState) {
          url = `${process.env.REACT_APP_LIKE}/${tinyUrl}?command=like`;
          // url = `${process.env.REACT_APP_LIKE}/${tinyUrl}?command=disLike`;
        } else {
          url = `${process.env.REACT_APP_LIKE}/${tinyUrl}?command=like`;
        }
      }
      let config3 = {
        method: "post",
        url: url,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        cancelToken: cancelTokenSource.token,
      };
      const response3 = await axios(config3);
    } catch (error) {
      console.log(error.message);

      //navigate("/error")
    }
  };
  const handleLogin = (source) => {
    setPreviousLocation(window.location.pathname);
    if (source == "like") {
      setReqForLike(true);
    }
    localStorage.setItem("previousLocationLocal", window.location.pathname);
    // navigate(`/signup`, { state: { like: true } });
    setShowLogin(true);
  };
  const goToContest = () => {
    const a = "contest.goviral.world";
    const b = shareFullData?.orgDomain;
    const c = shareFullData?.campaignUniqueText;
    const d = shareFullData?.contestUniqueText;
    let contestRoute = "/" + a + "/" + b + "/" + c + "/" + d + ".html";
    window.open(`/${contestRoute}`, "_self");
  };
  const a = "contest.goviral.world";
  const b = shareFullData?.orgDomain;
  const c = shareFullData?.campaignUniqueText;
  const d = shareFullData?.contestUniqueText;
  let contestRoute = "/" + a + "/" + b + "/" + c + "/" + d + ".html";
  function ShareHandle() {
    setShare(share ? false : true);
  }
  const [profilePicture, setProfilePicture] = useState("");

  useEffect(() => {
    if (!_.isEmpty(shareFullData)) {
      let url = `https://storage.googleapis.com/goviralprofiledatas/${shareFullData?.uid}`;
      setProfilePicture(url);
    }
  }, [shareFullData]);
  const dpOrName = () => {
    if (_.isEmpty(profilePicture)) {
      return shareFullData?.participantName?.charAt(0);
    } else {
      return (
        <img
          src={profilePicture}
          alt=""
          className="h-[100%] w-[100%] object-cover"
          onError={() => setProfilePicture("")}
        />
      );
    }
  };
  const handleLike = () => {
    setReqForLike(true);

    if (currentUser) {
      LikeHandle(likeState, "normal");
    } else {
      if (contestMetaData?.likeClosed) {
        setLikeMessage(
          `Unfortunately, we are unable to accept any more votes at this time as the contest has officially concluded.`
        );
        setLikePopup(true);
      } else {
        setWhatsappLikePopup(true);
      }
    }
  };
  const pageBgStyleGradient = encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" width="781" height="688" viewBox="0 0 781 688" fill="none">
  <g filter="url(#filter0_f_3339_1290)">
    <path d="M214.087 478.697C305.032 584.994 464.928 597.438 571.225 506.493C677.521 415.548 689.966 255.653 599.021 149.356L566.604 111.467C475.659 5.17072 315.763 -7.27399 209.466 83.6711C103.17 174.616 90.7251 334.512 181.67 440.808L214.087 478.697Z" fill="url(#paint0_radial_3339_1290)"/>
  </g>
  <defs>
    <filter id="filter0_f_3339_1290" x="0.187973" y="-97.8111" width="780.314" height="785.787" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="60.323" result="effect1_foregroundBlur_3339_1290"/>
    </filter>
    <radialGradient id="paint0_radial_3339_1290" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(288.704 330.705) rotate(-107.784) scale(259.431 243.666)">
      <stop offset="0.55" stop-color="${campaignTemplateData?.color1}"/>
      <stop offset="0.919792" stop-color="${campaignTemplateData?.color2}"/>
    </radialGradient>
  </defs>
</svg>
`);

  const desciptionBgGradient =
    encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" width="390" height="572" viewBox="0 0 390 572" fill="none">
<g filter="url(#filter0_f_3318_1137)">
  <path d="M61.3732 192.634C8.70003 265.81 25.3214 367.832 98.4981 420.505C171.675 473.178 273.696 456.557 326.369 383.38L328.627 380.244C381.3 307.067 364.679 205.046 291.502 152.373C218.325 99.6996 116.304 116.321 63.6306 189.498L61.3732 192.634Z" fill="url(#paint0_radial_3318_1137)"/>
</g>
<defs>
  <filter id="filter0_f_3318_1137" x="-90.0405" y="0.958969" width="570.081" height="570.96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="60.323" result="effect1_foregroundBlur_3318_1137"/>
  </filter>
  <radialGradient id="paint0_radial_3318_1137" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(156.017 229.842) rotate(-29.7607) scale(156.07 154.989)">
    <stop offset="0.180208" stop-color="${campaignTemplateData?.color1}"/>
    <stop offset="0.820833" stop-color="${campaignTemplateData?.color2}"/>
  </radialGradient>
</defs>
</svg>`);
  const pageBgStyle = {
    background: `url("data:image/svg+xml;charset=utf-8,${pageBgStyleGradient}") no-repeat top center`,
    backgroundSize: "auto",
  };
  const descriptionStyle = (width) => {
    if (width < 768) {
      return {
        background: `url("data:image/svg+xml;charset=utf-8,${desciptionBgGradient}") no-repeat top center`,
        backgroundSize: "auto",
      };
    } else {
      return {};
    }
  };
  const contestDetailsBg = {
    background: `linear-gradient(42deg, ${campaignTemplateData?.color1}fa 0%, ${campaignTemplateData?.color2} 76.12%)`,
  };

  const participantNameBg = {
    // background: `${
    //   campaignTemplateData?.participantNameColor
    //     ? campaignTemplateData?.participantNameColor
    //     : "linear-gradient(167deg, #A36A93 15.10%, #7795B4 76.56%)"
    // }`,
    // backgroundClip: "text",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent",
  };
  const handleProfileClick = () => {
    if (currentUser) {
      setShowProfileDropdown((prevState) => !prevState);
    } else {
      handleLogin("profile");
    }
  };
  const handleCloseCertificatePopup = () => {
    setShowCertificate(false);
  };
  const handleViewCertificate = () => {
    const userId = shareFullData?.userId;
    const organizationId = shareFullData?.orgId;
    const campaignId = shareFullData?.campaignId;
    const contestId = shareFullData?.contestId;
    const entryId = shareFullData?._id;

    const shortUrl =
      "certu" +
      userId +
      "o" +
      organizationId +
      "cp" +
      campaignId +
      "cn" +
      contestId +
      "e" +
      entryId;
    navigate(`/${shortUrl}`);
  };
  const navigateToCoupons = () => {
    const targetDiv = document.getElementById("couponDiv");
    if (targetDiv) {
      targetDiv.scrollIntoView({
        behavior: "smooth", // Use smooth scrolling
        block: "start", // Align the top of the target element with the top of the visible area
      });
    }
  };
  return (
    <>
      {likePopup && (
        <LikePopup
          setPopup={setLikePopup}
          backgroundColor={shareFullData?.buttonColor || "#ceba03"}
          message={likeMessage}
        />
      )}
      {whatsappLikePopup && (
        <WhatsappLike
          setPopup={setWhatsappLikePopup}
          entry={shareFullData}
          type="vote"
        />
      )}
      {verificationPopup && (
        <WhatsappLike
          setPopup={setVerificationPopup}
          entry={shareFullData}
          type="verification"
        />
      )}
      {serviceForm && (
        <ServiceForm
          service={service}
          setServiceForm={setServiceForm}
          orgDomain={shareFullData?.orgDomain}
        />
      )}
      {showCertificate && (
        <ParticipationCertificate
          onClose={handleCloseCertificatePopup}
          userId={shareFullData?.userId}
        />
      )}
      {loading ? (
        <Lottie />
      ) : (
        <>
          {entryRemoved && <PageNotFound status={shareFullData?.status} />}
          <div
            className=" text-black w-[100%] max-w-[1280px] mx-auto flex flex-col items-center  md:pb-[30px]"
            style={pageBgStyle}
          >
            <div className="pt-[16px] px-[20px] md:px-[116px] flex gap-[30px] flex-col-reverse md:flex-row  items-center justify-between w-[100%]">
              <div className="flex items-center gap-[10px] w-full md:w-[50%]">
                <div className="flex rounded-[7px] md:h-[52px] md:w-[52px] min-w-[44px] md:min-w-[52px]  h-[44px] w-[44px] bg-blue-400  items-center justify-center overflow-hidden">
                  {dpOrName()}
                </div>
                <div className={`${styles.textLargeMedium} text-white w-full`}>
                  <span style={{ fontWeight: 700 }}>
                    {shareFullData?.participantName}
                    {"'s "}
                  </span>
                  submission to the
                  {shareFullData?.contestType == "WP" ? "prediction" : ""}{" "}
                  contest{" "}
                  <a
                    href={`https://${contestRoute}`}
                    target="_blank"
                    className="underline hover:underline underline-offset-2"
                    style={{ color: shareFullData?.buttonColor }}
                  >
                    {shareFullData?.contestName} <span>{"\u00BB"}</span>
                  </a>
                </div>
              </div>
              <div className="flex w-full md:w-[50%] gap-[20px] items-center  justify-between md:justify-end">
                <div>
                  {(!_.isEmpty(coupons) || !_.isEmpty(ads)) && (
                    <img
                      src={couponButton}
                      className="md:h-[52px] h-[44px] cursor-pointer animate-pulse"
                      onClick={navigateToCoupons}
                    />
                  )}
                </div>
                <div className="flex items-center gap-[10px]">
                  {!_.isEmpty(selectedCoupons) && (
                    <div
                      className="bg-[#D9D9D9] rounded-full p-[5px] md:h-[52px] md:w-[52px] min-w-[44px] md:min-w-[52px]  h-[44px] w-[44px] flex items-center justify-center"
                      onClick={() => setShowCouponCart((prev) => !prev)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                      >
                        <path
                          d="M6.76633 4.88889H28L25.1111 15H8.32191M26.5556 20.7778H9.22222L6.33333 2H2M10.6667 26.5556C10.6667 27.3533 10.02 28 9.22222 28C8.42448 28 7.77778 27.3533 7.77778 26.5556C7.77778 25.7578 8.42448 25.1111 9.22222 25.1111C10.02 25.1111 10.6667 25.7578 10.6667 26.5556ZM26.5556 26.5556C26.5556 27.3533 25.9089 28 25.1111 28C24.3133 28 23.6667 27.3533 23.6667 26.5556C23.6667 25.7578 24.3133 25.1111 25.1111 25.1111C25.9089 25.1111 26.5556 25.7578 26.5556 26.5556Z"
                          stroke="black"
                          stroke-width="2.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  )}
                  {currentUser && (
                    <div
                      className=" items-center gap-[10px] flex self-end relative py-[6px] px-[10px] transition duration-300 ease-in"
                      onClick={handleProfileClick}
                      style={{
                        background: showProfileDropdown ? " " : "",
                        color: showProfileDropdown ? "#F6F6F6" : "#F6F6F6",
                      }}
                    >
                      <div className="flex md:h-[52px] md:w-[52px] min-w-[44px] md:min-w-[52px]  h-[44px] w-[44px] bg-blue-400  items-center justify-center rounded-full">
                        <img
                          src={
                            currentUser?.photoURL ? currentUser?.photoURL : user
                          }
                          alt=""
                          className="h-full w-full object-cover rounded-full overflow-hidden cursor-pointer z-10"
                        />
                      </div>
                      {showProfileDropdown && <ProfileDropDown />}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-[20px] md:mt-[40px] w-[100%] flex flex-wrap md:flex-nowrap relative h-auto md:h-[390px] px-[20px] md:px-[116px]">
              <div
                className="relative  w-full  md:w-[55%]  md:h-[100%] rounded-tr-[12px] rounded-tl-[12px] md:rounded-tl-[30px] md:rounded-tr-[0px] md:rounded-bl-[30px] bg-[#171717]"
                // style={entryBgStyle}
              >
                <div className=" w-[100%] h-full md:h-[100%] max-h-[340px] md:max-h-[530px] overflow-hidden flex justify-center  mx-auto rounded-tr-[12px] rounded-tl-[12px] md:rounded-tl-[30px] md:rounded-tr-[0px] md:rounded-bl-[30px]">
                  {/* Shared Media Shown here */}
                  <div
                    className="w-[100%] flex items-center justify-center"
                    onMouseDown={(e) => e.preventDefault()}
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    {!shareFullData?.fileType.startsWith("audio") && (
                      <EntryCarousel
                        items={shareFullData?.fileUrls}
                        thumbanil={shareFullData?.thumbnailUrl}
                        currentItem={currentItem}
                        setCurrentItem={setCurrentItem}
                      />
                    )}
                    {shareFullData?.fileType?.startsWith("audio") && (
                      <div className="h-full p-[20px] ">
                        <img
                          src={shareFullData?.thumbnailUrl}
                          className={`h-[80%] w-[100%] mx-auto object-contain mb-[10px] rounded-[12px] md:rounded-[30px]`}
                        />
                        <AudioPlayerWithoutDownload
                          fileUrl={
                            shareFullData?.fileUrls &&
                            shareFullData?.fileUrls[0] &&
                            shareFullData?.fileUrls[0]?.fileUrl
                          }
                          fileName="my voice recording"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className=" flex md:flex-col flex-col-reverse w-full md:w-[45%] text-white  h-full justify-center items-start md:justify-between relative rounded-br-[12px] rounded-bl-[12px] md:rounded-bl-[0px] md:rounded-br-[30px] md:rounded-tr-[30px] bg-[#171717] md:py-[30px] p-[20px] md:pl-[30px] md:pr-[40px]"
                style={contestDetailsBg}
              >
                <div>
                  {/* <div className="flex items-center w-[100%] gap-[8px] mb-[20px]">
                    <p className=" text-regular-medium">
                      Check out the contest I participated in:
                    </p>
                  </div> */}
                  {/* <p className="mb-[20px] text-regular-medium">
                    Your support means a lot to me! A like from your side would
                    be much appreciated. Thanks in advance! 😊
                  </p> */}
                  <a
                    href={`https://${contestRoute}`}
                    target="_blank"
                    className="md:block hidden"
                  >
                    <div className={styles.contestCard}>
                      <img
                        src={shareFullData?.contestPoster}
                        className="w-[23%] h-[89px] object-cover "
                      />
                      <div className="w-[73%]">
                        <p
                          className={styles.contestCardName}
                          style={{
                            color: shareFullData?.buttonColor
                              ? shareFullData?.buttonColor
                              : "#ceba03",
                          }}
                        >
                          {shareFullData?.contestName}
                        </p>
                        <p className="text-smallest text_Ell mt-[8px]">
                          {shareFullData?.contestDescription}
                        </p>
                        <p
                          className=" text-smallest mt-[5px] flex items-center gap-[5px]"
                          style={{ color: campaignTemplateData?.color1 }}
                        >
                          {" "}
                          View Details{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="5"
                            height="8"
                            viewBox="0 0 5 8"
                            fill="none"
                          >
                            <path
                              d="M0.206388 0.177553C0.478209 -0.0591841 0.918958 -0.0591841 1.19077 0.177553L4.59272 3.14345C5.13593 3.61699 5.13572 4.38436 4.59231 4.85771L1.18827 7.82244C0.916452 8.05919 0.475703 8.05919 0.203876 7.82244C-0.0679586 7.58568 -0.0679586 7.20184 0.203876 6.96508L3.11734 4.42759C3.38923 4.19085 3.38923 3.80698 3.11734 3.57025L0.206388 1.03489C-0.065446 0.798159 -0.065446 0.414349 0.206388 0.177553Z"
                              fill={campaignTemplateData?.color1}
                            />
                          </svg>
                        </p>
                      </div>
                    </div>
                  </a>

                  {/* <div className="block md:hidden">
                  <p
                    className={`mt-[10px] mb-[30px] text-regular opacity-[80%] text_5Line`}
                  >
                    {shareFullData?.description}
                  </p>
                  {formAttributes?.formAttributes?.map((el) => {
                    return (
                      <div className="flex flex-col gap-[20px] mb-[30px]">
                        {el?.items?.map((key) => {
                          if (shareFullData[key] && el?.display) {
                            return (
                              <>
                                <p className="text-regular-medium text-[#ffffff]">
                                  {el?.title}{" "}
                                </p>
                                <p className="text-regular-callout  text-[#606060]">
                                  {shareFullData[key]}
                                </p>
                              </>
                            );
                          }
                        })}
                      </div>
                    );
                  })}
                </div> */}
                </div>
                <div className=" flex gap-[24px] items-center  justify-end  w-[100%] md:mb-[0] md:mt-[20px] mb-[20px] ">
                  <div className="flex items-center gap-[5px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      style={{
                        filter: "drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.25))",
                      }}
                    >
                      <path
                        d="M18.75 7.34375C18.75 12.8125 10.6414 17.2391 10.2961 17.4219C10.2051 17.4708 10.1033 17.4965 10 17.4965C9.89665 17.4965 9.79492 17.4708 9.70391 17.4219C9.35859 17.2391 1.25 12.8125 1.25 7.34375C1.25145 6.05955 1.76223 4.82837 2.6703 3.9203C3.57837 3.01223 4.80955 2.50145 6.09375 2.5C7.70703 2.5 9.11953 3.19375 10 4.36641C10.8805 3.19375 12.293 2.5 13.9062 2.5C15.1904 2.50145 16.4216 3.01223 17.3297 3.9203C18.2378 4.82837 18.7486 6.05955 18.75 7.34375Z"
                        fill={likeState ? "#E07578" : "white"}
                        stroke={likeState ? "#fcc0c0" : "white"}
                        stroke-width="1"
                      />
                    </svg>

                    <p className="text-large text-white ">{likeCount}</p>
                    <button
                      className={`transition duration-300 ease-in ${
                        styles.button
                      }  ${likeState ? styles.likedButton : ""}`}
                      onClick={handleLike}
                      disabled={likeLoading}
                    >
                      {!likeLoading && (
                        <>
                          <p className="text-large text-white ">
                            {likeState ? "Votes" : "Click to Vote"}
                          </p>
                        </>
                      )}

                      {likeLoading && <span className={styles.loader} />}
                    </button>
                  </div>

                  <button
                    className={styles.button}
                    onClick={() => setShare(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="19"
                      viewBox="0 0 26 19"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.8299 16.1295L24.5203 9.73585C24.8239 9.4898 25 9.12148 25 8.73259C25 8.3437 24.8239 7.97556 24.5203 7.72933L16.8299 1.33572C16.4197 0.987929 15.8456 0.902652 15.3507 1.11607C14.8557 1.3295 14.5269 1.80423 14.5036 2.33898V4.86074C3.7069 3.00555 1 12.5892 1 18C3.50461 13.8781 9.99499 6.40639 14.5036 12.5892V15.1177C14.5239 15.6541 14.8515 16.1317 15.3469 16.3472C15.8425 16.5626 16.4185 16.4781 16.8299 16.1295Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {/* <p className="text-large text-white ">Share</p> */}
                  </button>
                </div>
                {shareFullData?.fileUrls?.length > 1 && (
                  <div className="flex md:hidden pb-[20px] items-center justify-center w-full gap-[5px]">
                    {shareFullData?.fileUrls?.map((_, index) => (
                      <CarouselDot
                        key={index}
                        active={index === currentItem}
                        id={`dot-${index}`}
                        // onClick={() => handleSlideChange(index)}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div
              className="mt-[22px] place-self-start w-full flex flex-col-reverse md:flex-row gap-[30px] relative h-auto  px-[20px] md:px-[116px]"
              style={descriptionStyle(window.innerWidth)}
            >
              <div className="w-full md:w-[50%]">
                {shareFullData?.description && (
                  <div className="mb-[30px]">
                    <p
                      className={`${styles.textDescriptionHeading} text-[#F6F6F6] mb-[5px]`}
                      style={{ color: shareFullData?.buttonColor }}
                    >
                      Description
                    </p>
                    <p className={`${styles.textDescription} text-[#fff] `}>
                      {shareFullData?.description}
                    </p>
                  </div>
                )}
                {formAttributes?.formAttributes?.map((el) => {
                  return (
                    <div className="flex flex-col ">
                      {el?.items?.map((key) => {
                        if (
                          shareFullData &&
                          shareFullData[key] &&
                          el?.display
                        ) {
                          if (el?.attrType == "media") {
                            return (
                              <div className="pb-[30px]">
                                <p
                                  className={`${styles.textDescriptionHeading} text-[#F6F6F6] mb-[10px]`}
                                  style={{ color: shareFullData?.buttonColor }}
                                >
                                  {el?.title}{" "}
                                </p>
                                {el?.type?.startsWith("image") && (
                                  <div className=" h-[160px] overflow-hidden flex items-center justify-center mb-[5px] rounded-[5px] bg-[#1c1c1c] px-[15px] py-[18px]">
                                    <img
                                      src={shareFullData[key]}
                                      className="w-auto h-[100%]"
                                    />
                                  </div>
                                )}
                                {el?.type?.startsWith("video") && (
                                  <div className="h-[320px] w-[100%] overflow-hidden flex items-center justify-center mb-[15px] bg-[#1c1c1c] rounded-[12px] md:rounded-[30px]">
                                    <ReactPlayer
                                      playing={false}
                                      controls={true}
                                      width="100%"
                                      height="100%"
                                      url={shareFullData[key]}
                                      style={{ objectFit: "contain" }}
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          } else {
                            return (
                              <>
                                <p
                                  className={`${styles.textDescriptionHeading} text-[#F6F6F6] mb-[7px]`}
                                  style={{ color: shareFullData?.buttonColor }}
                                >
                                  {el?.title}{" "}
                                </p>
                                <p
                                  className={`${styles.textDescription} text-[#fff] mb-[30px]`}
                                >
                                  {shareFullData[key]}
                                </p>
                              </>
                            );
                          }
                        }
                      })}
                    </div>
                  );
                })}
              </div>
              {campaignTemplateData?.certificate && (
                <div className="md:w-[50%]">
                  <div
                    className={styles.certificateDiv}
                    style={{ color: shareFullData?.buttonColor }}
                    onClick={handleViewCertificate}
                  >
                    <div>
                      <p>Certificate issued for</p>
                      <p className="flex gap-[7px] justify-center items-center">
                        {shareFullData?.participantName}
                      </p>
                    </div>
                    <div className="w-[150px] h-[80px] flex justify-center  overflow-hidden">
                      <img
                        src={certificateImage}
                        className="w-auto h-full object-contain "
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {share && (
        <ShareInShare
          showShare={ShareHandle}
          fullData={shareFullData}
          type="sharepage"
        />
      )}
    </>
  );
};

export default MoreAbout;
