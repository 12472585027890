import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Context from "../context/Context";
import Lottie from "../Components/lottie/finalupload/finalUploadloading";
import axios from "axios";

function MetaDataLoader(props) {
  const { Component } = props;
  const navigate = useNavigate();
  const { setTinyUrlFromMetaData } = useContext(Context);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const tinyUrlCustom = window.location.pathname
      .split("/")[1]
      ?.replace(/\.html$/, "");
    loadPromotionMetaData(tinyUrlCustom);
  }, []);

  const loadPromotionMetaData = async (slugId) => {
    setLoading(true);
    setTinyUrlFromMetaData("");
    try {
      const data = await axios.get(
        `https://storage.googleapis.com/goviral_promotions/slugId/${slugId}/metaData.json`
      );
      const metaData = data.data;
      const tinyUrlFromMetaData = `pro${metaData?.orgId}ent${metaData?.promoId}`;

      setTinyUrlFromMetaData(tinyUrlFromMetaData);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  return <div>{loading ? <Lottie /> : <Component />}</div>;
}

export default MetaDataLoader;
