import React, { useRef } from "react";
import styles from "../Share/Share.module.css";
import "./Share.css";
import Context from "../../context/Context";
import { useContext, useState, useEffect } from "react";
import NewShare from "./NewShare";
import NoContest from "./NoContest";
import NoContestBlack from "../ShareBlackTemplate/NoContestBlack";
import MoreAbout from "./MoreAbout";
import axios from "axios";
import Ads from "./Ads";
import ExclusiveServices from "./ExclusiveServices";
import Reviews from "./Reviews";
import Leads from "./Leads";
import BlackLeadsFooter from "../ShareBlackTemplate/BlackLeadsFooter";
import AdWhite from "./AdWhite";
import { useUserAuth } from "../../context/UserAuthContext";
import UserLinkGenration from "../ShareBlackTemplate/UserLinkGenration";
import { useNavigate } from "react-router";
import PromoterSharePopup from "./PromoterSharePopup";
import ShareInShare from "./ShareInShare";
import { updateProfile } from "firebase/auth";
import SignupPage from "../../Pages/SignupPage";
import PromotionBanner from "../PromotionBanner/PromotionBanner";
import Footer from "../ShareBlackTemplate/Footer";
import PromotorsDetials from "../PromotorsDetials/PromotorsDetials";

import PromotorsDetialsCampaign from "../PromotorsDetials/PromotorsDetialsCampaign";
import UserLinkGenrationCampaign from "../ShareBlackTemplate/UserLinkGenrationCampaign";
import FooterCampaignTemplate from "../ShareBlackTemplate/FooterCampaignTemplate";
import { isEmpty } from "lodash";
import DetailsPopup from "./DetailsPopup";
import Coupons from "./Coupons";
import CouponCart from "../CouponCart/CouponCart";
import CartBanner from "../CouponCart/CartBanner";
// import "../PromotionTemplate/dance.css";
// import "../PromotionTemplate/template.css";
// import "../PromotionTemplate/templateChandrayaan.css";
// import "../PromotionTemplate/templateOnam.css";

function TinyShareGrid() {
  const { userIdToken, selectedCoupons, tinyUrlFromMetaData } =
    useContext(Context);

  const tinyUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");

  const entryId = tinyUrl.split("ent").pop().split("inf")[0];
  const organizationId = tinyUrl.split("o").pop().split("ent")[0];
  let orgId = tinyUrl.split("o").pop().split("cp")[0];
  let campaignId = tinyUrl.split("cp").pop().split("cn")[0];
  let contestId = tinyUrl.split("cn").pop().split("e")[0];
  let videoId = tinyUrl.split("e").pop();
  let promotion = tinyUrl.startsWith("pro");
  let promotionId = tinyUrl.split("prou").pop().split("ent")[0];
  let promotionEntryId = tinyUrl.split("ent").pop().split("inf")[0];
  let promotionInfId = tinyUrl.split("inf").pop().split("c")[0];
  const [promotionData, setPromotionData] = useState({});
  const [generateLinkLoading, setGenerateLinkLoading] = useState(false);
  const [share, setShare] = useState(false);
  const [newPromotorId, setNewPromotorId] = useState();
  const [promotorShare, setPromotorShare] = useState(false);
  const [campaignTemplateData, setCampaignTemplateData] = useState({});
  const [cssUrl, setCssUrl] = useState("");
  const [promotionDataJson, setPromotionDataJson] = useState({});
  const { currentUser, showLogin, setShowLogin, setPreviousLocation } =
    useUserAuth();
  const token = userIdToken || currentUser?.accessToken;
  const navigate = useNavigate();
  const initialValues = {
    name: currentUser?.displayName ? currentUser?.displayName : "",
    email: currentUser?.email ? currentUser?.email : "",
    phone: currentUser?.phoneNumber ? currentUser?.phoneNumber : "",
  };
  const [influencerFormValue, setInfluencerFormValue] = useState(initialValues);
  const [inflencerDetails, setInflencerDetails] = useState({});
  const [showCouponCart, setShowCouponCart] = useState(false);
  const [entryIndId, setEntryInfId] = useState();
  const couponRef = useRef;
  useEffect(() => {
    verifyUser();
    loadDetails(orgId, campaignId);
    // window.scrollTo({
    //   top: 300,
    //   behavior: "smooth"
    // })
  }, []);
  const scrollDown = () => {
    window.scrollTo({
      top: 400,
      behavior: "smooth",
    });
  };
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [shareFullData, setShareFullData] = useState();
  const [service, setService] = useState();
  const [serviceForm, setServiceForm] = useState(false);
  const [registerForm, setRegisterForm] = useState(false);
  const [detailsPopup, setDetailsPopup] = useState(false);
  // const [instaQr, setInstaQr] = useState()
  // const [reviews, setReviews] = useState();

  const { verifyUser, openDetail } = useContext(Context);

  const [ads, setads] = useState([]);
  const [infAds, setInfAds] = useState([]);
  const [campaignInfAds, setCampaignInfAds] = useState([]);
  const [coupons, setCoupons] = useState([]);
  // const [serviceDetails, setServiceDetails] = useState([]);

  // let sponserDetails = shareFullData?.mainSponser

  const loadDetails = async (orgId, campaignId) => {
    try {
      //loadServiceDetails(orgId, campaignId)
      scrollDown();
      setTimeout(() => {
        scrollUp();
      }, "1000");
      loadAdsDetails(orgId, campaignId);
      //loadReviewDetails(orgId, campaignId)
    } catch (err) {
      console.log(err.message);
    }
  };
  const handleLogin = () => {
    setPreviousLocation(window.location.pathname);
    setShowLogin(true);
    // navigate(`/signup`, { state: { promotionLogin: true } });
  };
  function ShareHandle() {
    setShare(share ? false : true);
  }
  const handleShareLink = async () => {
    setGenerateLinkLoading(true);
    try {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_PROMOTOR_GENERATE_LINK}/${entryId}/${promotionDataJson?.organizationId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setGenerateLinkLoading(false);
        setNewPromotorId(response?.data);
        setPromotorShare(true);
      }
    } catch (error) {
      setGenerateLinkLoading(false);
    }
  };
  const getInfDetails = async () => {
    setGenerateLinkLoading(true);
    try {
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_PROMOTOR_GET_DETAILS}/${entryId}/${promotionDataJson?.organizationId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setGenerateLinkLoading(false);
        setNewPromotorId(response?.data);
        if (!isEmpty(response?.data?.infDetails)) {
          setInfluencerFormValue((prevState) => ({
            ...prevState,
            ...response?.data?.infDetails,
          }));
        }
        setPromotorShare(true);
      }
    } catch (error) {
      setGenerateLinkLoading(false);
    }
  };
  const createInfluencer = async () => {
    setGenerateLinkLoading(true);

    try {
      let url = "";
      if (newPromotorId?.newInfluencer) {
        url = `${process.env.REACT_APP_CREATE_INFLUENCER}/${entryId}/${promotionDataJson?.organizationId}`;
      } else {
        url = `${process.env.REACT_APP_PROMOTOR_UPDATE_AND_REQUEST}/${entryId}/${promotionDataJson?.organizationId}`;
      }
      let config = {
        method: "post",
        url: url,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: influencerFormValue,
      };
      const response = await axios(config);
      if (response.status === 200) {
        setNewPromotorId({
          ...promotionId,
          approvedByAdmin: false,
          newInfluencer: false,
        });
        updateUserssProfile();
        setGenerateLinkLoading(false);
      }
    } catch (error) {
      setGenerateLinkLoading(false);
    }
  };
  const updateUserssProfile = async () => {
    try {
      const updateUserProfile = await updateProfile(currentUser, {
        displayName: influencerFormValue?.name,
        phoneNumber: influencerFormValue?.phone,
      });
      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_UPDATE_PROFILE_DETAILS}`,
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
          "Content-Type": "application/json",
        },
        data: influencerFormValue,
      };
      const updateProfileDetails = await axios(config);
    } catch (error) {
      console.log("error", error);
    }
  };
  const loadAdsDetails = async (orgId, campaignId) => {
    try {
      if (promotion) {
        // const data = await axios.get(
        //   `${process.env.REACT_APP_SHARE_JSON_URL}/7/6/ads1.json`,
        //   `${process.env.REACT_APP_PROMOTION_SHARE_JSON_URL}/${promotionEntryId}/${promotionId}/ads/ads.json`,
        // )
        // setads(data.data)
      } else {
        const data = await axios.get(
          // `${process.env.REACT_APP_SHARE_JSON_URL}/7/6/ads1.json`,
          `${process.env.REACT_APP_SHARE_JSON_URL}/${orgId}/${campaignId}/ads.json`
        );
        setads(data.data?.ads?.ads);
        setCoupons(data.data?.discountCoupons);
      }
    } catch (error) {
      console.log(error.message);
      //navigate("/error")
    }
  };
  const loadInfluencerAdsDetails = async (orgId, promotionId, infId) => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_PROMOTION_ENTRY_DETAILS_JSON}/${orgId}/${promotionId}/${infId}/influencerDetails.json`
      );
      const promotionDataJson = data.data;
      setPromotionDataJson(promotionDataJson);
      setads(promotionDataJson?.ads?.ads);
    } catch (error) {
      console.log(error.message);
    }
  };
  const loadCmpInfluencerAdsDetails = async (infId, orgId, campaignId) => {
    try {
      setEntryInfId(infId);
      const data = await axios.get(
        `https://storage.googleapis.com/goviralinfluencers/${infId}/campaign/${orgId}/${campaignId}/ads.json`
      );
      setCampaignInfAds(data?.data?.ads);
    } catch (error) {
      console.log(error.message);
    }
  };
  // const loadServiceDetails = async (orgId, campaignId) => {
  //   try {
  //     const data = await axios.get(
  //       `${process.env.REACT_APP_SHARE_JSON_URL}/${orgId}/${campaignId}/service.json`
  //     );
  //     setServiceDetails(data.data);
  //   } catch (error) {
  //     console.log(error.message);
  //     //navigate("/error")
  //   }
  // };

  // setWhatsUpLink(serviceDetails?.whatsUpUrl);
  // const loadReviewDetails = async (orgId, campaignId) => {
  //   try {
  //     const data = await axios.get(
  //       `${process.env.REACT_APP_SHARE_JSON_URL}/${orgId}/${campaignId}/reviews.json`
  //     );
  //     setReviews(data.data);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };
  const handleClickCounterPromotion = async (
    brandId,
    promotionId,
    id,
    adsName,
    brandName,
    type,
    catType
  ) => {
    const tinyUrlCustom = tinyUrl.replace(/c/g, "");
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_HIT_COUNTER_PROMOTION}/${brandId}/${promotionId}/${id}/${tinyUrlCustom}?type=${type}&catType=${catType}`,
      data: {
        name: adsName,
        brandName: brandName,
        token: token ? token : "null",
      },
    };

    const response1 = await axios(config);
  };
  const handleClickCounterCampaign = async (
    brandId,
    id,
    adsName,
    brandName,
    type,
    catType
  ) => {
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_HIT_COUNTER_CAMPAIGN}/${campaignId}/${orgId}/${brandId}/${id}?type=${type}&catType=${catType}`,
      data: {
        name: adsName,
        brandName: brandName,
        token: token ? token : "null",
        infId: entryIndId,
      },
    };

    const response1 = await axios(config);
  };

  const campaignFooterLine = {
    background: `radial-gradient(50% 50.00% at 50% 50.00%, ${campaignTemplateData?.color1} 55.52%, rgba(62, 26, 87, 0.00) 91.98%)`,
  };

  useEffect(() => {
    if (tinyUrl.endsWith("c")) {
      setCssUrl(
        `${process.env.REACT_APP_PROMOTION_ENTRY_DETAILS_JSON}/${organizationId}/${entryId}/${promotionInfId}/template.css`
      );
    } else {
      setCssUrl(
        `${process.env.REACT_APP_PROMOTION_ENTRY_DETAILS_JSON}/${organizationId}/${entryId}/template.css`
      );
    }
  }, [tinyUrl]);

  return (
    <div className="tamplate-sharepage-container ">
      <link rel="stylesheet" type="text/css" href={cssUrl} />
      {promotionDataJson?.templateCss && (
        <link
          rel="stylesheet"
          type="text/css"
          href={promotionDataJson?.templateCss}
        />
      )}
      <div className="bg-[black] relative ">
        {showLogin && <SignupPage />}
        {/* {serviceForm && (
          <ServiceForm
            service={service}
            setServiceForm={setServiceForm}
            orgDomain={shareFullData?.orgDomain}
          />
        )} */}

        {showCouponCart && (
          <CouponCart
            setShowCouponCart={setShowCouponCart}
            setCoupons={setCoupons}
          />
        )}
        {!isEmpty(selectedCoupons) && (
          <CartBanner setShowCouponCart={setShowCouponCart} />
        )}
        {promotion ? (
          <>
            {/* <NoContestBlack
              serviceForm={serviceForm}
              setServiceForm={setServiceForm}
              service={service}
              setService={setService}
              promotionData={promotionData}
              setPromotionData={setPromotionData}
              setads={setads}
              promotionDataJson={promotionDataJson}
              setPromotionDataJson={setPromotionDataJson}
              handleClickCounterPromotion={handleClickCounterPromotion}F
            /> */}
            <PromotionBanner
              promotionDataJson={promotionDataJson}
              setPromotionDataJson={setPromotionDataJson}
              promotionData={promotionData}
              setPromotionData={setPromotionData}
              setads={setads}
              setCoupons={setCoupons}
              serviceForm={serviceForm}
              setServiceForm={setServiceForm}
              registerForm={registerForm}
              setRegisterForm={setRegisterForm}
              service={service}
              handleClickCounterPromotion={handleClickCounterPromotion}
              loadInfluencerAdsDetails={loadInfluencerAdsDetails}
              inflencerDetails={inflencerDetails}
            />
          </>
        ) : (
          <NewShare
            serviceForm={serviceForm}
            setServiceForm={setServiceForm}
            service={service}
            setService={setService}
            campaignTemplateData={campaignTemplateData}
            setCampaignTemplateData={setCampaignTemplateData}
            shareFullData={shareFullData}
            setShareFullData={setShareFullData}
            setShowCouponCart={setShowCouponCart}
            loadCmpInfluencerAdsDetails={loadCmpInfluencerAdsDetails}
            coupons={coupons}
            ads={ads}
          />
        )}
        <div id="couponDiv">
          {openDetail === false && !isEmpty(coupons) && (
            <div className=" md:max-w-[100%]">
              <Coupons
                coupons={coupons}
                setCoupons={setCoupons}
                handleClickCounterCampaign={handleClickCounterCampaign}
              />
            </div>
          )}
          {openDetail === false && (
            <div className=" md:max-w-[100%]  ">
              {campaignInfAds?.length != 0 && (
                <Ads
                  ads={campaignInfAds}
                  promotion={promotion}
                  setService={setService}
                  setDetailsPopup={setDetailsPopup}
                  setServiceForm={setServiceForm}
                  handleClickCounterPromotion={handleClickCounterPromotion}
                  handleClickCounterCampaign={handleClickCounterCampaign}
                  promotionEntryId={promotionEntryId}
                  fontLinks={promotionDataJson?.ads?.fontLink}
                />
              )}
            </div>
          )}
          {openDetail === false && (
            <div className=" md:max-w-[100%]  ">
              {infAds?.length != 0 && (
                <Ads
                  ads={infAds}
                  promotion={promotion}
                  setService={setService}
                  setDetailsPopup={setDetailsPopup}
                  setServiceForm={setServiceForm}
                  handleClickCounterPromotion={handleClickCounterPromotion}
                  handleClickCounterCampaign={handleClickCounterCampaign}
                  promotionEntryId={promotionEntryId}
                  fontLinks={promotionDataJson?.ads?.fontLink}
                />
              )}
            </div>
          )}
          {openDetail === false && (
            <div className=" md:max-w-[100%]  ">
              {ads?.length != 0 && (
                <Ads
                  ads={ads}
                  promotion={promotion}
                  setService={setService}
                  setServiceForm={setServiceForm}
                  setDetailsPopup={setDetailsPopup}
                  handleClickCounterPromotion={handleClickCounterPromotion}
                  handleClickCounterCampaign={handleClickCounterCampaign}
                  promotionEntryId={promotionEntryId}
                  fontLinks={promotionDataJson?.ads?.fontLink}
                />
              )}
            </div>
          )}
        </div>

        {/* Reviews */}
        {/* {reviews && (
          <Reviews reviews={reviews?.reviews} title={reviews?.heading} />
        )} */}
        {/* Leads */}
      </div>
      {promotionDataJson?.galleryPlacement == "bottom" &&
        !promotionDataJson?.hideGallery &&
        (promotionDataJson?.campaignPromotion ? (
          <>
            {promotionDataJson?.templateType == "DEFAULT" ? (
              <PromotorsDetials
                promotionDataJson={promotionDataJson}
                setServiceForm={setServiceForm}
                handleClickCounterPromotion={handleClickCounterPromotion}
              />
            ) : (
              <div className="template-company-bg w-full ">
                <div className="w-full max-w-[1440px] px-0 md:px-[8.3%] pb-[32px] md:pb-[140px] pt-[32px] md:pt-[60px] mx-auto">
                  <PromotorsDetialsCampaign
                    promotionDataJson={promotionDataJson}
                    setServiceForm={setServiceForm}
                    handleClickCounterPromotion={handleClickCounterPromotion}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="bg-[#001254] w-full ">
            <div className="w-full max-w-[1440px] px-0 md:px-[8.3%] pb-[32px] md:pb-[140px] pt-[32px] md:pt-[60px] mx-auto">
              <PromotorsDetials
                promotionDataJson={promotionDataJson}
                setServiceForm={setServiceForm}
                handleClickCounterPromotion={handleClickCounterPromotion}
              />
            </div>
          </div>
        ))}
      {promotionDataJson?.showCreateInfluencer == true &&
        (promotionDataJson?.campaignPromotion ? (
          <>
            {promotionDataJson?.templateType == "DEFAULT" ? (
              <UserLinkGenration
                generateLinkLoading={generateLinkLoading}
                handleLogin={handleLogin}
                handleShareLink={handleShareLink}
                setPromotorShare={setPromotorShare}
                promotionDataJson={promotionDataJson}
              />
            ) : (
              <UserLinkGenrationCampaign
                generateLinkLoading={generateLinkLoading}
                handleLogin={handleLogin}
                handleShareLink={handleShareLink}
                promotionDataJson={promotionDataJson}
                handleClickCounterPromotion={handleClickCounterPromotion}
                setPromotorShare={setPromotorShare}
              />
            )}
          </>
        ) : (
          <UserLinkGenration
            generateLinkLoading={generateLinkLoading}
            handleLogin={handleLogin}
            handleShareLink={handleShareLink}
            setPromotorShare={setPromotorShare}
          />
        ))}
      {/* NOT USING NOW AS PER DISCUSSION */}
      {/* {promotionDataJson?.showCurrentinfluencers === true && (
              <InfluencerGenerationLink
                generateLinkLoading={generateLinkLoading}
                handleLogin={handleLogin}
                handleShareLink={handleShareLink}
              />
            )} */}
      {!promotion && (
        <div className="bg-black w-100%  pt-[30px]">
          <div
            className=" w-full mx-auto max-w-[1440px] px-0 md:px-[8.3%] h-[6px] "
            style={campaignFooterLine}
          />
        </div>
      )}
      {promotion && promotionDataJson?.campaignPromotion && (
        <div className="bg-[#0c0c0d] w-100%  pt-[30px]">
          <div className=" w-full mx-auto max-w-[1440px] px-0 md:px-[8.3%] h-[6px] template-footer-line" />
        </div>
      )}
      {promotion &&
        !isEmpty(promotionDataJson) &&
        (promotionDataJson?.campaignPromotion ? (
          <>
            {promotionDataJson?.templateType == "DEFAULT" ? (
              <Footer template={promotionDataJson?.templateType} />
            ) : (
              <FooterCampaignTemplate />
            )}
          </>
        ) : (
          <Footer template={promotionDataJson?.templateType} />
        ))}
      {!promotion && (
        <>
          <Footer template={promotionDataJson?.templateType} />
          {/* <BlackLeadsFooter color={shareFullData?.buttonColor} /> */}
        </>
      )}
      {share && <ShareInShare showShare={ShareHandle} />}
      {promotorShare && (
        <PromoterSharePopup
          setPromotorShare={setPromotorShare}
          promotionData={promotionDataJson}
          influencerData={newPromotorId}
          influencerFormValue={influencerFormValue}
          setInfluencerFormValue={setInfluencerFormValue}
          generateLinkLoading={generateLinkLoading}
          createInfluencer={createInfluencer}
          getInfDetails={getInfDetails}
          handleLogin={handleLogin}
        />
      )}
      {detailsPopup && (
        <DetailsPopup
          data={service}
          setPopup={setDetailsPopup}
          promotionData={promotionDataJson}
        />
      )}
    </div>
  );
}

export default TinyShareGrid;
