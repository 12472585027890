import React from "react";
import RegistrationFooter from "../Components/RegistationFooter/RegistrationFooter";

export default function RegistationHomepage() {
  return (
    <div>
      <RegistrationFooter />
    </div>
  );
}
