import React from "react";
import ReactPlayer from "react-player";
import styles from "./Template4.module.css";

export default function Template4({
  carousel,
  handleOpenForm,
  handleLinkClick,
}) {
  return (
    <div
      className="  py-[30px]"
      style={{ background: carousel?.bgColor, color: carousel?.textColor }}
    >
      {/* {carousel.heading && (
        <p className="text-[18px] md:text-[21px] text-white font-medium mt-[22px]">
          {carousel.heading}
        </p>
      )} */}
      <div className="w-[100%] flex flex-col items-center ">
        {carousel?.logo && <img className={styles.logo} src={carousel.logo} />}
        {carousel?.heading && (
          <p className={styles.heading} style={{ fontFamily: carousel?.font }}>
            {carousel.heading}
          </p>
        )}

        {carousel?.btnText && (
          <div
            className=" px-[24px] py-[8px] mt-[16px] rounded-[30px] font-semibold text-[12px] flex items-center justify-center  hover:cursor-pointer "
            style={{
              background: carousel?.btnColor,
              color: carousel?.btnTextColor,
            }}
            onClick={() => {
              if (carousel?.goto === "form") {
                handleOpenForm(carousel);
              } else {
                handleLinkClick(carousel);
              }
            }}
          >
            {carousel?.btnText}
          </div>
        )}
      </div>
      <div
        className={` h-[100%] flex box w-[100%] gap-[16px] pr-[16px] overflow-x-auto  
        ${
          carousel.items.length < 5
          ? "md:justify-center md:pl-[0px] pl-[16px]"
          : "md:pl-[100px] pl-[16px]"
        }`}
      >
        {carousel &&
          carousel.items &&
          carousel.items.map((add) => {
            return (
              <div
                className="md:w-[262px] w-[262px] h-[333px]  flex flex-col bg-[black]  mt-[25px] relative"
                // style={{backgroundColor: add?.bgColor}}
                onClick={() => {
                  if (carousel?.goto === "form") {
                    handleOpenForm(add);
                  } else {
                    handleLinkClick(add);
                  }
                }}
              >
                {add?.fileType?.startsWith("image") && (
                  <img
                    src={add.fileUrl}
                    alt=""
                    className="min-w-[264px] md:w-[264px]  md:h-[100%]  w-[264px] h-[100%] object-cover "
                  />
                )}
                {add.fileType?.startsWith("video") && (
                  <div className="min-w-[264px] md:w-[264px] md:h-[100%] w-[264px]  h-[100%] object-cover ">
                    <ReactPlayer
                      config={{
                        file: {
                          attributes: {
                            controlsList: "nodownload",
                          },
                        },
                        youtube: { playerVars: { disablekb: 1 } },
                      }}
                      // playing={true}
                      controls={true}
                      width="100%"
                      light={add?.thumbnailUrl ? add?.thumbnailUrl : true}
                      height="100%"
                      url={add?.fileUrl}
                      className={styles.reactPlayer}
                    />
                  </div>
                )}
              </div>
            );
          })}
      </div>
      {/* {carousel.items.length > 2 &&
              <div className='w-[100%] flex items-center justify-end mt-[10px]'>
                <p className='text-[14px] text-[#111111] mr-[10px] italic'>Swipe left to view more</p>
                <img src={swipe} alt="" className='h-[24px] w-[24px]' />
              </div>
            } */}
    </div>
  );
}
