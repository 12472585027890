import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { useUserAuth } from "../context/UserAuthContext";
import Context from "../context/Context";
import SignupPage from "./SignupPage";
import RegistationDetails from "../Components/RegistationDetails/RegistationDetails";
import PurchaseDetails from "../Components/PurchaseDetails/PurchaseDetails";
import { useSearchParams } from "react-router-dom";

export default function PurchaseDetailsPage() {
  const { userIdToken, update, tinyUrlFromMetaData } = useContext(Context);
  const domain = window.location.href.split("/")[2];
  const tinyUrl =
    tinyUrlFromMetaData ||
    window.location.pathname.split("/")[1]?.replace(/\.html$/, "");
  const organizationId = tinyUrl.split("o").pop().split("ent")[0];
  const entryId = tinyUrl.split("ent").pop().split("inf")[0];
  const [loading, setLoading] = useState(false);
  const [retryLoading, setRetryLoading] = useState(false);
  const [promotionDataJson, setPromotionDataJson] = useState({});
  const [registrationDetails, setRegistrationDetails] = useState({});
  const [paymenStatus, setPaymentStatus] = useSearchParams();
  let paymentCode = paymenStatus.get("code");
  const { regId } = useParams();

  const {
    currentUser,
    setPreviousLocation,
    showLogin,
    setCustomLogin,
    setShowLogin,
  } = useUserAuth();
  const token = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    loadRegistationDetails();
  }, [update]);

  useEffect(() => {
    loadJsonDetails();
  }, []);
  const loadJsonDetails = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_PROMOTION_ENTRY_DETAILS_JSON}/${organizationId}/${entryId}/promotion.json`
      );
      const promotionDataJson = data.data;
      setPromotionDataJson(promotionDataJson);
      // if (!currentUser) {
      //   setPreviousLocation(window.location.pathname);
      //   setShowLogin(true);
      //   setCustomLogin(promotionDataJson?.loginBanner);
      // }
    } catch (err) {
      console.log(err.message);
    }
  };
  const loadRegistationDetails = async () => {
    try {
      setLoading(true);
      let config = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_SINGLE_PURSHASE}/${organizationId}/${entryId}/${regId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      setRegistrationDetails(response?.data?.data);
      const gatewayRespInput = document.getElementById("gatewayresp");
      if (gatewayRespInput) {
        const gatewayRespValue = gatewayRespInput.value;
        if (paymentCode) {
          setPaymentStatus({ code: paymentCode });
        } else {
          if (
            gatewayRespValue == "__GATEWAYSTAT__" ||
            gatewayRespValue == "NA"
          ) {
            setPaymentStatus({ code: response?.data?.data?.paymentStatus });
          } else {
            setPaymentStatus({ code: gatewayRespValue });
          }
        }
      }
      setLoading(false);
    } catch (err) {
      console.log(err.message);
    }
  };
  const submitFormApi = async () => {
    setLoading(true);
    try {
      const { shareUrl, orderId, orgId, engagementId, paymentModes } =
        registrationDetails;
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_INITIATE_PAYMENT_PHONEPE_HOMAM}/${orgId}/${engagementId}/${orderId}?paymentMode=${paymentModes}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          tinyUrl: shareUrl,
        },
      };
      const submitPay = await axios(config);
      if (submitPay?.data?.status == "success") {
        let paymentRedirectUrl = submitPay?.data?.paymentRedirectUrl;
        let url = submitPay?.data?.paymentUrl;
        handleRedirectToLink(url, paymentRedirectUrl);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  function handleRedirectToLink(link, paymentRedirectUrl) {
    const form = document.createElement("form");
    form.method = "get";
    form.action =
      paymentRedirectUrl || "https://www.shootmates.com/thirdpartyPayment";
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "gatewayresp";
    input.id = "gatewayresp";
    input.value = link;
    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
  }
  return (
    <div>
      {showLogin && <SignupPage />}
      <PurchaseDetails
        details={registrationDetails}
        loading={loading}
        retryLoading={retryLoading}
        handleClick={submitFormApi}
        promotionDataJson={promotionDataJson}
        paymentCode={paymentCode}
      />
    </div>
  );
}
